<template>
    <div>
        <b-modal id="confirm--cancel-subscription" class="px-0" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" modal-class="cr__modal" content-class="cr__content" dialog-class="cr__dialog d-flex justify-content-center" size="sm" no-close-on-backdrop>
			<div class="container-fluid">
				<div class="d-flex justify-content-end mb-2">
					<button
						class="btn button--cr__close d-flex align-items-center justify-content-center"
						@click="$bvModal.hide('confirm--cancel-subscription')"
					>
						<font-awesome-icon icon="fa-solid fa-xmark"/>
					</button>
				</div>
				<div class="cr--main d-flex flex-column">
					<h4 class="cr--title">{{ $t('cancel-subscription') }}</h4>
                    <h6 class="current__plan mt-3">{{ $t('your-current-plan') }}</h6>
					<div class="cr--list-role d-flex flex-column mb-5">
                        <div class="pr--package-card">
                            <div class="cr--role__card">
                                <div class="cr--role__card-inner px-4 py-3">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <div class="col-4 text-left mb-2">
                                                <span style="color: #8C8C8C; font-size: 14px;">{{ $t('plans') }}</span>
                                            </div>
                                            <div class="col-4 text-left mb-2 d-flex justify-content-end" v-if="planProgress.label_discount && planProgress.package_bill == 'annual'">
                                                <span class="badge-discount mr-2">{{ planProgress.label_discount }}% off</span>
                                            </div>
                                        </div>
    
                                        <div class="row d-flex align-items-center justify-content-between">
                                            <div class="d-flex align-items-start text-left col-12">
                                                <div class="col-5">
                                                    <h6 class="package--title mr-2">{{ planProgress.package_name ? planProgress.package_name : '-' }}</h6>
                                                </div>
                                                <div class="col-7">
                                                    <h6 class="price--package">IDR {{ planProgress.package_price ? convertCurrency(planProgress.package_price) : '0k' }}<span >{{ planProgress.package_bill == null || planProgress.package_bill == 'monthly' ? $t('per-month') : $t('per-year') }}</span></h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row pr--body">
                            <p class="par--1 mb-0">
                                {{ $t('cancel-subscription--p1-start') }} <span class="subs--date">{{ planProgress.next_invoice_date ? getDateAndYearOnly(planProgress.next_invoice_date) : '-' }}</span>. {{ $t('cancel-subscription--p1-end') }}
                            </p>
                            <p class="par--1 my-3">
                                {{ $t('cancel-subscription--p2') }}
                            </p>
                            <p class="par--3 mb-0">
                                {{ $t('cancel-subscription--p3') }}
                            </p>
                        </div>
					</div>
				</div>
			</div>
            <div class="modal--footer d-flex align-items-center justify-content-center pt-3 pb-2">
                <button class="btn btn--gray mr-3" @click="$bvModal.hide('confirm--cancel-subscription')">{{ $t('not-now') }}</button>
                <!-- <button class="btn btn--purple" @click="confirm">Confirm</button> -->
                <button class="btn btn--purple" @click="$emit('confirm-cancel')">{{ $t('next') }}</button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import moment from 'moment';
import 'moment/locale/id'; 
import 'moment/locale/en-in';
export default {
    props: {
        planProgress: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
	data() {
		return {
			roles: [
				{
					image: require('@/assets/images/icons/ic_educator-content.svg'),
					title: 'Edu Creator',
					description: 'I create content for schools or universities'
				},
			]
		}
	},

	methods: {
		handleChooseRole() {
			this.$router.push({ name : 'Soca-Certified-Creator' })
		},
        confirm(){
            this.$bvModal.hide('confirm--cancel-subscription');
            this.$bvModal.show('sendfeedback--cancel-subscription');
        },
        convertCurrency(amount){
            if (amount >= 1000) {
                return (amount / 1000).toFixed() + "K";
            }
            return amount.toString();
        },
        getDateAndYearOnly(data){
            const date = moment(data).utc();
            const previousDay = date.subtract(1, 'day');

            if(localStorage.getItem('locale') == 'id'){
                moment.locale('en');
            }else{
                moment.locale('id');
            }

            return previousDay.format('DD MMMM YYYY');
        },
	}
}
</script>

<style scoped>
.badge-discount{
    background-color: #2D2D2D;
    padding: 4px 14px;
    border-radius: 12px;
}
.pr--body{
    padding: 0px 2rem;

}
.pr--package-card{
    padding: 0px 3rem;
}
.btn--gray{
    border-radius: 4px;
    background: #2D2D2D;
    color: #FFF;
}
.btn--purple{
    border-radius: 4px;
    background: #6D75F6;
    color: #FFF;
}
.modal--footer{
    border-top: 0.5px solid #2D2D2D;
}
.subs--date{
    color: #FFF;
    font-family: 'Avenir Next';
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
}
.par--1, .par--2, .par--3{
    color: #FFF;
    font-family: Avenir Next;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.package--title{
    color: #FFF;
    font-family: Avenir Next;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
}
.price--package span{
    font-weight: 100 !important;
}
.price--package{
    color: #FFF;
    font-style: normal;
    font-weight: 700 !important;
    font-family: Avenir Next;
    font-size: 20px;
}

.current__plan{
    color: #8C8C8C;
    font-family: Avenir Next;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
}
.button--cr__close {
	width: 27px;
	height: 27px;
	border-radius: 50%;
	background-color: #fff;
	color: black;
}

.cr--main {
	gap: 8px;
}

.cr--list-role {
	gap: 24px;
}

.cr--title {
	color: #FFF;
	font-size: 22px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
    text-align: center;
}

.cr--role__card {
	border-radius: 8px;
	background-image: linear-gradient(to bottom, #FFFFFF33, #ffffff00);
	/* background-image: 1px solid rgba(255, 255, 255, 0.20); */
	padding: 2px;
}

.cr--role__card-inner {
	background: linear-gradient(0deg, #121418 0%, #121418 100%), rgba(49, 48, 54, 0.30);
	border-radius: 8px;
	backdrop-filter: blur(2px);
}

.cr--role__main {
	gap: 4px;
}

.cr--role__title {
	color: #FFF;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.cr--role__description {
	color: #FFF;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
</style>

<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#confirm--cancel-subscription .modal-content{
    border-radius: 12px !important;
    background-color: #1f1f1f;
    font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
	color: #fff;
}

.cr__modal + .modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}

.cr__dialog {
	max-width: none;
}

.cr__content {
	width: 491px;
}
</style>
