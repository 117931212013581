<template>
    <section class="d-flex justify-content-center text-white profile">
        <div class="w-100 px-3 mt-3">
            <h3 class="text-left mb-4 title-page ml-2">My Account</h3>
            <vs-tabs :color="colorx" v-model="activeTab">
                <vs-tab label="General">
                    <div class="container-fluid w-100">
                        <div class="row">
                            <div class="col-7">
                                <div class="container-fluid">
                                    <div class="row user-profile mt-2">
                                        <div class="col-md-12 d-flex align-items-center">
                                            <div class="position-relative">
                                                <img :src="users.picture" v-if="users.picture != null" :alt="users.name" class="profile-picture rounded-circle cursor-pointer" @click="$bvModal.show('change-profile')">
                                                <img v-else :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + users.name" :alt="users.name" class="profile-picture rounded-circle cursor-pointer" @click="$bvModal.show('change-profile')">
                                                <img src="@/assets/images/icons/ic-change-img.svg" alt="coin" class="ic-change-profile">
                                            </div>
                                            <div class="ml-3 w-75 text-left">
                                                <div class="d-flex align-items-end mb-1">
                                                    <img v-if="isVerified" class="mr-1" src="@/assets/images/icons/ic_soca-cc.svg" alt="Soca Certified Creator" title="Soca Certified Creator">
                                                    <h4 class="text-truncate">{{ users.name }}</h4>
                                                </div>
                                                <p class="mb-0 text-grey">Creator</p>
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-1 d-flex justify-content-center w-100">
                                            <hr>
                                        </div>
                                        <div class="col-md-4 d-flex">
                                            <div class="col-4 d-flex align-items-center justify-content-center">
                                                <div>
                                                    <h4 class="fs-18">{{accountInfo.content}}</h4>
                                                    <p class="mb-0 mt-1 text-muted">Contents</p>
                                                </div>
                                            </div>
                                            <div class="col-4 d-flex align-items-center justify-content-center">
                                                <div>
                                                    <h4 class="fs-18">{{accountInfo.plays}}</h4>
                                                    <p class="mb-0 mt-1 text-muted">Plays</p>
                                                </div>
                                            </div>
                                            <div class="col-4 d-flex align-items-center justify-content-center">
                                                <div>
                                                    <h4 class="fs-18">{{accountInfo.player}}</h4>
                                                    <p class="mb-0 mt-1 text-muted">Players</p>
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- <div class="col-md-1 d-flex justify-content-center w-100">
                                            <hr>
                                        </div>
                                        <div class="col-md-2 d-flex align-items-center justify-content-end">
                                            <div class="d-flex align-items-center justify-content-end">
                                                <div class="pill-point d-flex align-items-center">
                                                    <img src="@/assets/images/icons/ic_coins.svg" alt="coin" class="ml-2">
                                                    <div class="d-flex align-items-center ml-1">
                                                        <h5 class="text-white">{{ formatScore(accountInfo.final_score) }}</h5>
                                                        <span class="text-white mt-1 ml-1 mr-2" style="font-size:8px;">Points</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="detail-informasi">
                                    <div class="form-row mt-3">
                                        <b-form-group label="Full Name" id="name-group" label-for="name" class="col-md-6 text-left">
                                            <b-form-input type="text" v-model="formData.name" :state="fullNameState()" id="name" placeholder="Input Your Full Name"></b-form-input>
                                            <b-form-invalid-feedback :state="fullNameState()">
                                                This field is required.
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                        <b-form-group label="Email" id="email-group" label-for="email" class="col-md-6 text-left">
                                            <b-form-input type="email" readonly v-model="formData.email" id="email" class="read-only"></b-form-input>
                                        </b-form-group>
                                        <b-form-group label="Phone" id="no-hp-group" label-for="no-hp" class="col-md-6 text-left">
                                            <b-form-input type="number" v-model="formData.phone_number" :state="phoneNumberState()" id="no-hp" placeholder="Input Your Phone Number" min="1000000" max="999999999999999999999999999999" class="no-spinner"></b-form-input>
                                        </b-form-group>
                                        <b-form-group label="Birth Date" id="ttl-group" label-for="ttl" class="col-md-6 text-left">
                                            <b-form-input type="date" v-model="formData.birth_date" id="ttl"></b-form-input>
                                        </b-form-group>
                                        <b-form-group label="Address" id="alamat-group" label-for="alamat" class="col-md-12 text-left">
                                            <b-form-textarea v-model="formData.address" id="alamat" placeholder="Input Your Address"></b-form-textarea>
                                        </b-form-group>
                                    </div>
                                    <div class="d-flex mt-3 mb-4 align-items-center justify-content-end">
                                        <button class="btn btn-primary btn-secondary mr-2" @click="cancel">Cancel</button>
                                        <button class="btn btn-primary btn-purple px-4" :disabled="!phoneNumberState() || !fullNameState()" @click="updateAccount">Save</button>
                                    </div>
                                </div>
                                <div class="mt-3 options">
                                    <div class="list-option">
                                        <div class="my-2 inner-option">
                                            <hr>
                                            <div class="d-flex mt-4 align-items-center justify-content-between">
                                                <div class="d-flex align-items-center">
                                                    <img src="@/assets/images/icons/ic_lock.svg" alt="Event">
                                                    <p class="mb-0 ml-2 title-p">Password</p>
                                                </div>
                                                <div class="d-flex align-items-center accordion">
                                                    <button :class="visible ? 'button-p cursor-pointer accordion-button d-none' : 'button-p cursor-pointer accordion-button'" :aria-expanded="visible ? true : false" aria-controls="accordion" @click="visible = !visible">Change Password</button>
                                                </div>
                                            </div>
                                            <b-collapse id="accordion" accordion="my-accordion" v-model="visible" role="tabpanel">
                                                <div class="container-fluid mt-4 mx-0">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <b-form-group label="New Password" id="new-pw-group" label-for="new-pw" class="text-left mr-3">
                                                                <b-form-input :type="typeInput1" v-model="newPassword" id="new-pw" placeholder="Input New password" @input="checkPasswordStrength"></b-form-input>
                                                                <img :src="require(`@/assets/images/icons/${ic_eye_1}`)" alt="Show Password" class="position-absolute cursor-pointer" @click="showPasswordNewPass" :style="typeInput1 == 'password' ? 'right: 24px; top: 40px;' : 'right: 24px; top: 37px;'">
                                                            </b-form-group>
                                                            <div v-if="newPassword" class="mt-3">
                                                                <div v-for="(data, index) in secure_password" :key="index" class="d-flex align-items-center">
                                                                    <img v-if="data.status" src="@/assets/images/icons/ic_check.svg" alt="">
                                                                    <img v-else src="@/assets/images/icons/ic_cross.svg" alt="">
                                                                    <span class="pl-2">{{ data.condition }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 text-left">
                                                            <b-form-group label="Confirm Password" id="confirm-pw-group" label-for="confirm-pw" class="text-left">
                                                                <b-form-input :type="typeInput2" v-model="confirmPassword" id="confirm-pw" placeholder="Confirm New password" :style="!is_password_match ? 'border: 2px solid #EB5757' : ''"></b-form-input>
                                                                <img :src="require(`@/assets/images/icons/${ic_eye_2}`)" alt="Show Password" class="position-absolute cursor-pointer" @click="showPasswordConfirmPass" :style="typeInput2 == 'password' ? 'right: 7px; top: 40px;' : 'right: 7px; top: 37px;'">
                                                            </b-form-group>
                                                            <span class="text-alert text-left mb-2" v-if="!is_password_match">
                                                                Passwords do NOT match!
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="row d-flex align-items-center justify-content-end">
                                                        <div class="d-flex mt-3 mb-4 align-items-center justify-content-end">
                                                            <button class="btn btn-primary btn-secondary mr-2" @click="visible = false, newPassword = null, confirmPassword = null">Cancel</button>
                                                            <button v-if="loading_password" class="btn btn-purple px-4">
                                                                <div class="spinner-border spinner-border-sm text-light" role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                </div>
                                                            </button>
                                                            <button v-else class="btn btn-purple px-4" :disabled="!is_password_secure" @click="postNewPassword">Save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-collapse>
                                            <hr>
                                            <div class="d-flex mt-4 align-items-center justify-content-between">
                                                <div class="d-flex align-items-center">
                                                    <img src="@/assets/images/icons/ic_pin.svg" alt="Event">
                                                    <p class="mb-0 ml-2 title-p">PIN</p>
                                                </div>
                                                <div class="d-flex align-items-center accordion">
                                                    <button class="button-p cursor-pointer" @click="actionPin()">{{ status_pin }}</button>
                                                </div>
                                            </div>
                                            <hr>

                                            <div class="d-flex align-items-center justify-content-between">
                                                <div class="d-flex align-items-center">
                                                    <img src="@/assets/images/icons/ic_lang.svg" alt="language" class="invert">
                                                    <p class="mb-0 ml-2 title-p">Language</p>
                                                </div>
                                                <div class="change-lang" ref="listLang">
                                                    <button class="btn button-lang d-flex justify-content-end align-items-center" @click="isLangShow = !isLangShow">
                                                        <span class="text-purple">{{ lang }}</span>
                                                        <img src="@/assets/images/icons/arrow-down.svg" alt="coin" class="ml-1 arrow" :class="{ 'arrow-up' : isLangShow}" width="11">
                                                    </button>
                                                    <div class="options-lang pb-1" :class="{ 'options-lang__show' : isLangShow}">
                                                        <div class="container">
                                                            <div class="row px-4 py-1 item-lang" :key="index" v-for="item,index in optionsLang" @click="changeLang(optionsLang[index].value)">
                                                                <div class="col-md-3 text-left">
                                                                    <span>{{ item.lang }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr>
                                            <div class="d-flex align-items-center cursor-pointer" @click="$bvModal.show('logout-modal')">
                                                <img src="@/assets/images/icons/ic_logout.svg" alt="logout" class="invert">
                                                <p class="mb-0 ml-2 title-p">Logout</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-5">
                                <div class="container-fluid">
                                    <div class="card--scc ml-5 p-4">
                                        <div class="card--scc__preview-video"></div>
                                        <div class="card--scc__inner d-flex flex-column mt-3">
                                            <div class="card--scc__header d-flex align-items-center justify-content-center">
                                                <img src="@/assets/images/icons/ic_soca-cc.svg" alt="Soca Certified Creator" title="Soca Certified Creator">
                                                <p class="card--scc__title m-0">Soca Certified Creator</p>
                                            </div>
                                            <p class="card--scc__description m-0">Join exceptional creators, recognized <br> and empowered with Soca Certification.</p>
                                            <div class="card--scc__list d-flex flex-column container-fluid">
                                                <div class="row text-left">
                                                    <div class="col-1">
                                                        <img src="@/assets/images/icons/ic_cb.svg" alt="Certified Badge">
                                                    </div>
                                                    <div class="col-11">
                                                        <p class="card--scc__list--title my-1">Certified Badge</p>
                                                        <p class="card--scc__list--subtitle m-0">Gain recognition and trust with our exclusive Certified Badge. Showcase your expertise as a Soca Certified Creator, setting yourself apart in the creative community.</p>
                                                    </div>
                                                </div>
                                                <div class="row text-left">
                                                    <div class="col-1">
                                                        <img src="@/assets/images/icons/ic_cm.svg" alt="Content Monetization">
                                                    </div>
                                                    <div class="col-11">
                                                        <p class="card--scc__list--title my-1">Content Monetization</p>
                                                        <p class="card--scc__list--subtitle m-0">Turning Creativity into Income. As a Soca Certified Creator, unlock access to content monetization tools that empower you to turn your passion into a revenue stream.</p>
                                                    </div>
                                                </div>
                                                <div class="row text-left">
                                                    <div class="col-1">
                                                        <img src="@/assets/images/icons/ic_c&a-protection.svg" alt="Account & Content Protection">
                                                    </div>
                                                    <div class="col-11">
                                                        <p class="card--scc__list--title my-1">Account & Content Protection</p>
                                                        <p class="card--scc__list--subtitle m-0">Your creativity deserves protection. With Soca's Certified Creator program, enjoy enhanced security features and account safeguards, ensuring your creative journey remains secure and uninterrupted.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="button" class="card--scc__button btn py-2 d-flex align-items-center justify-content-center" :class="{ 'scc--rejected' : sccRejected }" @click="handleRequestNow"><font-awesome-icon v-if="sccRejected" icon="fa-solid fa-xmark" class="scc--rejected"/> Request Now</button>
                                            <p v-if="sccRejected" class="card--scc__description m-0">You can re-apply in 5 days</p>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </vs-tab>
                <vs-tab :label="$t('plans')">
                    <div class="container-fluid">
                            <div class="row plans--pr" :class="{'custom': options_date == 'custom' && items_history_credit.length > 4}">
                                <div class="col-md-6">
                                    <!-- <div class="border-plan-billing py-4 mr-3 h-100 d-flex align-items-center flex-column justify-content-center container-fluid"> -->
                                    <div class="border-plan-billing py-4 mr-3 h-100 container-fluid px-5 d-flex flex-column" :class="{'justify-content-between align-items-start': planProgress, 'justify-content-center align-items-center': !planProgress}">

                                        <div v-if="planProgress" class="w-100">
                                            <div class="row">
                                                <div class="col-4 text-left">
                                                    <span style="color: #8C8C8C; font-size: 14px;">{{ $t('plans') }}</span>
                                                </div>
                                                <div class="col-4 text-left d-flex justify-content-end" v-if="planProgress.label_discount && planProgress.package_bill == 'annual'">
                                                    <span class="badge-discount mr-2" v-if="$i18n.locale == 'id'">Diskon {{ planProgress.label_discount }}%</span>
                                                    <span class="badge-discount mr-2" v-else-if="$i18n.locale == 'en'">{{ planProgress.label_discount }}% off</span>
                                                </div>
                                            </div>
                                            <div class="row d-flex align-items-center justify-content-between">
                                                <div class="d-flex align-items-center col-9 text-left">
                                                    <div class="col-xl-3 col-4">
                                                        <h6 class="package--title">{{ planProgress.package_name }}</h6>
                                                    </div>
                                                    <div class="col-7 ml-2" v-if="!planProgress.package_free">
                                                        <h6 class="price--package">IDR {{ planProgress.package_price ? convertCurrency(planProgress.package_price) : '0k' }}<span>{{ planProgress.package_bill == null || planProgress.package_bill == 'monthly' ? $t('per-month') : $t('per-year') }}</span></h6>
                                                    </div>
                                                </div>

                                                <div class="col-3 d-flex justify-content-end">
                                                    <!-- <button class="btn btn-primary btn-purple px-4 text-nowrap" v-if="planProgress.button_status == 'contact-us'" @click="navigation('https://wa.me/message/2FGAFWFOX6MQE1')">{{ $t('contact-us') }}</button> -->
                                                    <!-- <button class="btn btn-primary btn-purple px-4 text-nowrap" v-else-if="planProgress.button_status == 'reactivate'" @click="createInvoice()" :disabled="load_to_invoice">{{ load_to_invoice ? 'Loading...' : $t('re-acvive') }}</button> -->
                                                    <button class="btn btn-primary btn-purple px-4 text-nowrap" @click="$router.push({ name : 'TopUp' })">{{ $t('buy-now') }}</button>
                                                </div>
                                            </div>

                                            <div class="row mt-4 mb-5">
                                                <div class="col-12 mb-3 text-left">
                                                    <p class="mb-2">{{ printPlanProgress(planProgress.usage_audio_multilingual) }} {{ $t('of') }} {{ planProgress.audio_multilingual_alloc }} {{ $t('minutes') }} Multilingual Voice</p>
                                                    <b-progress :value="planProgress.percentage_audio_multilingual" :max="100" class="mb-3 prog-plan"></b-progress>
                                                </div>
                                                <div class="col-12 mb-3 text-left" v-if="!planProgress.package_free && planProgress.voice_model_multilingual_alloc">
                                                    <p class="mb-2">{{ planProgress.usage_voice_model_multilingual }} {{ $t('of') }} {{ planProgress.voice_model_multilingual_alloc}} {{ $t('voice-cloning') }}</p>
                                                    <b-progress :value="planProgress.percentage_voice_model_multilingual" :max="100" class="mb-3 prog-plan"></b-progress>
                                                </div>
                                            </div>
                                        </div>

                                        <div v-if="planProgress" class="row d-flex align-items-center justify-content-between w-100">
                                            <template v-if="!planProgress.package_free">
                                                <span class="badge--bill py-1 px-2">{{ planProgress.next_invoice_date ? $t('nextbill')+getDateAndYearOnly(planProgress.next_invoice_date) : '-' }}</span>
                                                <button class="btn btn--cancel-plan p-0" @click="$bvModal.show('confirm--cancel-subscription')" v-if="!planProgress.cancel_subscriptions">{{ $t('plan--p') }}</button>
                                                <span class="text-white p-0" v-else>{{ $t('canceled') }}</span>
                                            </template>
                                        </div>

                                        <div class="d-flex align-items-center flex-column justify-content-center" v-if="!planProgress">
                                            <span>{{ $t('no-have-package') }}</span>
                                            <button class="btn btn-primary btn-purple px-4 mt-3" @click="$router.push({ name : 'TopUp' })">{{ $t('btn-buy-packaged') }}</button>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="border-plan-billing pt-4 ml-3 h-100">
                                        <div class="d-flex align-items-center justify-content-between pl-4 pr-3" :class="{'mb-2': options_date != 'custom'}">
                                            <h4 class="text-truncate">{{ $t('credit-usages-history') }}</h4>
                                            <vs-select class="select--filter mb-2 text-left" label="" v-model="options_date">
                                                <vs-select-item :key="index" :value="item.key" :text="item.val" v-for="(item,index) in list_filter" />
                                            </vs-select>
                                        </div>
                                        <div :class="options_date != 'custom' ? 'd-none' :'d-flex align-items-center justify-content-end cust__date--picker mb-2 mr-4'">
                                            <el-date-picker
                                            v-model="custom_date"
                                            type="daterange"
                                            size="small"
                                            range-separator="-"
                                            start-placeholder="Start date"
                                            end-placeholder="End date"
                                            value-format="yyyy-MM-dd"
                                            :picker-options="pickerOptions"
                                            >
                                            </el-date-picker>
                                        </div>
                                        <div :class="{'h-100': items_history_credit.length < 1}">
                                            <b-table
                                                hover
                                                :items="items_history_credit"
                                                :fields="fields_credit_usages_history"
                                                :table-class="{'h-100': items_history_credit.length < 1}"
                                                :class="items_history_credit.length < 1 ? 'h-100 text-left' : 'text-left tableHistory'"
                                                :tbody-class="{'h-100': items_history_credit.length < 1}"
                                                show-empty
                                                responsive
                                                sticky-header
                                                :tbody-tr-class="{
                                                    'text-white table-credit-history' : items_history_credit.length > 0,
                                                    'border-none td--custom_el' : items_history_credit.length < 1
                                                }"
                                                ref="tableHistory"
                                                >
                                                <template #empty>
                                                    <p class="text-center">There is no history of credit use</p>
                                                </template>

                                                <template #cell(createdAt)="data">
                                                    <p class="mb-0">{{ data.item.createdAt ? getDate(data.item.createdAt) : '-' }}</p>
                                                </template>
                                                <template #cell(use_allocation)="data">
                                                    <p class="mb-0">{{ data.item.use_allocation ? printPlanProgress(data.item.use_allocation) : '-' }}</p>
                                                </template>
                                            </b-table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-12">
                                    <div class="border-plan-billing py-4">
                                        <div class="text-left mb-4 pl-4">
                                            <h4 class="text-truncate">{{ $t('transaction-history') }}</h4>
                                        </div>
                                        <div>
                                            <b-table hover :borderless="true" :items="items" :fields="fields" class="text-left" :per-page="perPage" show-empty>
                                                <template #empty>
                                                    <div class="d-flex justify-content-center">
                                                        <span class="text-center text-white">No Transactions at this time</span>
                                                    </div>
                                                </template>
                                                <template #cell(invoice_number)="data">
                                                    <div class="d-flex">
                                                        <img src="@/assets/images/icons/ic-paper.svg" alt="paper" class="mr-2">
                                                        <span class="title-items-table">{{ data.item.invoice_number }}</span>
                                                    </div>
                                                </template>
                                                <template #cell(createdAt)="data">
                                                    <span class="title-items-table">{{ getBillingDate(data.item.createdAt) }}</span>
                                                </template>
                                                <template #cell(payment_status)="data">
                                                    <ul class="list-inline table-action m-0">
                                                        <li class="list-inline-item" v-if="data.item.payment_status == 'PAID'">
                                                            <span size="sm" class="badge badge-paid">
                                                                <img src="@/assets/images/icons/paid.svg" alt="paper" class="mr-1">
                                                                Paid
                                                            </span>
                                                        </li>
                                                        <li class="list-inline-item" v-else-if="data.item.payment_status == 'UNPAID'">
                                                            <span size="sm" class="badge badge-unpaid">
                                                                <img src="@/assets/images/icons/unpaid.svg" alt="paper" class="mr-1">
                                                                Unpaid
                                                            </span>
                                                        </li>
                                                        <li class="list-inline-item" v-else-if="data.item.payment_status == 'PENDING'">
                                                            <span size="sm" class="badge badge-unpaid">
                                                                <img src="@/assets/images/icons/unpaid.svg" alt="paper" class="mr-1">
                                                                Pending
                                                            </span>
                                                        </li>
                                                        <li class="list-inline-item" v-else-if="data.item.payment_status == 'EXPIRED'">
                                                            <span size="sm" class="badge badge-unpaid">
                                                                <img src="@/assets/images/icons/unpaid.svg" alt="paper" class="mr-1">
                                                                Expired
                                                            </span>
                                                        </li>
                                                        <li class="list-inline-item" v-else>
                                                            <span size="sm" class="badge badge-unpaid">
                                                                <img src="@/assets/images/icons/unpaid.svg" alt="paper" class="mr-1">
                                                                {{ data.item.payment_status }}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    <!-- <div v-if="data.item.status == 'Paid'" class="title-items-table badge-table-paid d-flex align-items-center"> -->
                                                        <!-- <img src="@/assets/images/icons/paid.svg" alt="paper" class="mr-2">
                                                        <span>{{ data.item.status }}</span> -->
                                                    <!-- </div> -->
                                                    <!-- <div v-else-if="data.item.status == 'Unpaid'" class="title-items-table badge-table-unpaid d-flex align-items-center">
                                                        <img src="@/assets/images/icons/unpaid.svg" alt="paper" class="mr-2">
                                                        <span>{{ data.item.status }}</span>
                                                    </div> -->
                                                </template>
                                                <template #cell(final_amount)="data">
                                                    <span class="title-items-table">{{ formatPrice(data.item.final_amount) }}</span>
                                                </template>
                                                <template #cell(package_name)="data">
                                                    <span class="title-items-table">{{ data.item.package_name }}</span>
                                                </template>
                                                <template #cell(Action)>
                                                    <div class="d-flex align-items-end">
                                                        <img src="@/assets/images/icons/ic-download.svg" alt="paper" class="mr-2">
                                                        <span class="text-purple">Download</span>
                                                    </div>
                                                </template>
                                            </b-table>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between mt-3">
                                        <span>{{ $t('show') }} {{ items.length }} {{ $t('of') }} {{ totalData  }} {{ $t('data') }}</span>
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="rows"
                                                :per-page="perPage"
                                            aria-controls="my-table"
                                        ></b-pagination>
                                    </div>
                                </div>
                            </div>
                    </div>
                </vs-tab>
                <!-- <vs-tab label="API Keys">
                    <api-keys :is-generate="isGenerateKey" :is-get-data="isGetAPIKEY" :api-key="credentialsAPI"/>
                </vs-tab> -->
                <!-- <vs-tab label="Usage Dashboard">
                    <div class="ud--container d-flex flex-column text-left">
                        <h5 class="ud--apilog__text">See API LOG <span class="ud--apilog__here" @click="$router.push({ name : 'ApiLog' })">Here</span></h5>
                        <CustomCard>
                            <div class="d-flex flex-column cc--gap">
                                <p class="ud--title m-0">Character Usage</p>
                                <div class="d-flex align-items-center justify-content-between">
                                    <p class="ud--subtite m-0">Insights into {{ cuActive == 'Daily' ? 'daily' : 'cummulative' }} character usage.</p>
                                    <div class="ud--cu__action d-flex align-items-center cust__date--picker">
                                        <el-date-picker
                                            v-model="filterCU"
                                            type="month"
                                            value-format="yyyy-MM"
                                            placeholder="Pick a month">
                                        </el-date-picker>
                                        <button class="ud--cu__button btn" :class="{ 'ud--cu__button-active' : cuActive == 'Daily' }" @click="cuActive = 'Daily'">Daily</button>
                                        <button class="ud--cu__button btn" :class="{ 'ud--cu__button-active' : cuActive == 'Cummulative' }" @click="cuActive = 'Cummulative'">Cummulative</button>
                                    </div>
                                </div>
                                <div>
                                    <div class="label--chart" :class="{ 'label--chart__active' : cuActive == 'Daily' }">
                                        <p class="label--chart__text m-0">Daily Usage (Characters)</p>
                                        <Chart type="bar" :options="chartCUD" :series="seriesCUD"/>
                                    </div>
                                    <div class="label--chart" :class="{ 'label--chart__active' : cuActive == 'Cummulative' }">
                                        <p class="label--chart__text m-0">Cummulative Usage (Characters)</p>
                                        <Chart type="area" :options="chartCUC" :series="seriesCUC"/>
                                    </div>
                                </div>
                            </div>
                        </CustomCard>

                        <CustomCard>
                            <div class="d-flex flex-column cc--gap">
                                <p class="ud--title m-0">API Request</p>
                                <div class="d-flex align-items-center justify-content-between">
                                    <p class="ud--subtite m-0">A summary of your requests, aggregated per minute</p>
                                    <div class="d-flex align-items-center udr--date">
                                        <div :class="options_date_rd != 'custom' ? 'd-none' :'d-flex align-items-center justify-content-end cust__date--picker mb-2'">
                                            <el-date-picker
                                                v-model="custom_date_rd"
                                                type="daterange"
                                                size="small"
                                                range-separator="-"
                                                start-placeholder="Start date"
                                                end-placeholder="End date"
                                                value-format="yyyy-MM-dd"
                                                :picker-options="pickerOptions"
                                            >
                                            </el-date-picker>
                                        </div>
                                        <vs-select class="select--filter mb-2 text-left" label="" v-model="options_date_rd">
                                            <vs-select-item :key="index" :value="item.key" :text="item.val" v-for="(item,index) in list_filter" />
                                        </vs-select>
                                    </div>
                                </div>
                                <div>
                                    <p class="label--chart__text m-0">Total Request</p>
                                    <Chart type="bar" :options="chartAPI" :series="seriesAPI"/>
                                </div>
                            </div>
                        </CustomCard>
                    </div>
                </vs-tab> -->
                <!-- <vs-tab label="Workspace">
                    <template>
                        <div v-if="ownership_community.length == 0 && member_community.length == 0" class="ce__container d-flex flex-column justify-content-center align-items-center">
                            <h5 class="ce__small-text">You don't have a workspace</h5>
                            <div class="cca d-flex">
                                <button class="btn ce__button-cc" type="button" @click="$router.push({ name : 'Create-Community' })">Create Workspace</button>
                            </div>
                        </div>
                        <div v-else class="c__container">
                            <template v-if="ownership_community.length > 0">
                                <div class="d-flex align-items-center justify-content-between">
                                    <h4 class="c__title text-left mt-4 mb-3">Created By You</h4>
                                    <button class="btn ce__button-cc-sm text-nowrap" type="button" @click="$router.push({ name : 'Create-Community' })">Create Workspace</button>
                                </div>
                                <div class="c__list-community">
                                    <div v-for="(data, i) in ownership_community" :key="i" class="c__card d-flex flex-column justify-content-center align-items-center cursor-pointer" @click="detailCommunity(data._id, false, data.downgrade)">
                                        <img v-if="data.community_picture" :src="data.community_picture" :alt="data.community_name" class="c__card-img">
                                        <img v-else :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + data.community_name" :alt="data.community_name" class="c__card-img">
                                        <span class="c__card-title text-truncate mt-3">{{ data.community_name }}</span>

                                        <span class="c__card-subtitle" :class="{ 'c__card-subtitle-danger' : data.downgrade }"> <img v-if="data.downgrade" src="@/assets/images/icons/ic_adgc.svg" alt=""> {{ data.member ? data.member : '0' }} members</span>
                                        <div class="clp-container d-flex justify-content-between mt-2">
                                            <div class="c__clp d-flex flex-column justify-content-center align-items-center">
                                                <img src="@/assets/images/icons/ic_package_question.png" alt="Question" title="Question" class="c__clp-img">
                                                <p class="c__clp-title m-0" :title="data.community_allocations[0]?.question_generates ? data.community_allocations[0]?.question_generates : '0'">{{ truncatedText(data.community_allocations[0]?.question_generates ? data.community_allocations[0]?.question_generates : '0') }}</p>
                                                <p class="c__clp-subtitle m-0">questions</p>
                                            </div>
                                            <div class="c__clp d-flex flex-column justify-content-center align-items-center">
                                                <img src="@/assets/images/icons/ic_package_audio2.png" alt="Audio" title="Audio" class="c__clp-img">
                                                <p class="c__clp-title m-0 w-100 text-truncate" :title="data.community_allocations[0]?.audio_duration ? data.community_allocations[0]?.audio_duration : '0'">{{ truncatedText(data.community_allocations[0]?.audio_duration ? data.community_allocations[0]?.audio_duration : '0') }}</p>
                                                <p class="c__clp-subtitle m-0">minutes</p>
                                            </div>
                                            <div class="c__clp d-flex flex-column justify-content-center align-items-center">
                                                <img src="@/assets/images/icons/ic_package_img.png" alt="Image" title="Image" class="c__clp-img">
                                                <p class="c__clp-title m-0 w-100 text-truncate" :title="data.community_allocations[0]?.image_generates ? data.community_allocations[0]?.image_generates : '0'">{{ truncatedText(data.community_allocations[0]?.image_generates ? data.community_allocations[0]?.image_generates : '0') }}</p>
                                                <p class="c__clp-subtitle m-0">images</p>
                                            </div>
                                        </div>
                                        <button class="btn mt-3 button-active" :disabled="data.active" @click.stop="changeCommunity(data._id)">
                                            <font-awesome-icon v-if="data.active" icon="fa-solid fa-check"/>
                                            <span>
                                                {{ data.active ? 'Activated' : 'Active' }}
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="d-flex align-items-center justify-content-between">
                                    <h4 class="c__title text-left mt-4 mb-3">Created By You</h4>
                                    <button class="btn ce__button-cc-sm text-nowrap" type="button" @click="$router.push({ name : 'Create-Community' })">Create Workspace</button>
                                </div>
                                <div class="oc--empty">
                                    <h4 class="oce--text">You do not own any workspace</h4>
                                </div>
                            </template>
                            <template v-if="member_community.length > 0">
                                <div class="d-flex align-items-center justify-content-between" :class="ownership_community.length != 0 ? 'mt-4' : ''">
                                    <h4 class="c__title text-left mt-4 mb-3">Also, You’re Member</h4>

                                </div>
                                <div class="c__list-community">
                                    <div v-for="(data, i) in member_community" :key="i" class="c__card d-flex flex-column justify-content-center align-items-center cursor-pointer" @click="detailCommunity(data.dataCommunity[0]._id)">
                                        <img v-if="data.dataCommunity[0].community_picture" :src="data.dataCommunity[0].community_picture" :alt="data.dataCommunity[0].community_name" class="c__card-img">
                                        <img v-else :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + data.dataCommunity[0].community_name" :alt="data.dataCommunity[0].community_name" class="c__card-img">
                                        <span class="c__card-title text-truncate mt-3">{{ data.dataCommunity[0].community_name }}</span>
                                        <span class="c__card-subtitle">{{ data.dataCommunity[0].member ? data.dataCommunity[0].member : '0' }} members</span>
                                        <div class="clp-container d-flex justify-content-between mt-2">
                                            <div class="c__clp d-flex flex-column justify-content-center align-items-center">
                                                <img src="@/assets/images/icons/ic_package_question.png" alt="Question" title="Question" class="c__clp-img">
                                                <p class="c__clp-title m-0" :title="data.dataCommunity[0].data_allocations[0].question_generates ? data.dataCommunity[0].data_allocations[0].question_generates : '0'">{{ truncatedText(data.dataCommunity[0].data_allocations[0].question_generates ? data.dataCommunity[0].data_allocations[0].question_generates : '0') }}</p>
                                                <p class="c__clp-subtitle m-0">questions</p>
                                            </div>
                                            <div class="c__clp d-flex flex-column justify-content-center align-items-center">
                                                <img src="@/assets/images/icons/ic_package_audio2.png" alt="Audio" title="Audio" class="c__clp-img">
                                                <p class="c__clp-title m-0" :title="data.dataCommunity[0].data_allocations[0].audio_duration ? data.dataCommunity[0].data_allocations[0].audio_duration : '0'">{{ truncatedText(data.dataCommunity[0].data_allocations[0].audio_duration ? data.dataCommunity[0].data_allocations[0].audio_duration : '0') }}</p>
                                                <p class="c__clp-subtitle m-0">minutes</p>
                                            </div>
                                            <div class="c__clp d-flex flex-column justify-content-center align-items-center">
                                                <img src="@/assets/images/icons/ic_package_img.png" alt="Image" title="Image" class="c__clp-img">
                                                <p class="c__clp-title m-0 w-100 text-truncate" :title="data.dataCommunity[0].data_allocations[0].image_generates_quota ? data.dataCommunity[0].data_allocations[0].image_generates_quota : '0'">{{ truncatedText(data.dataCommunity[0].data_allocations[0].image_generates_quota ? data.dataCommunity[0].data_allocations[0].image_generates_quota : '0') }}</p>
                                                <p class="c__clp-subtitle m-0">images</p>
                                            </div>
                                        </div>
                                        <button class="btn mt-3 button-active" :disabled="data.active" @click.stop="changeCommunity(data.dataCommunity[0]._id)">
                                            <font-awesome-icon v-if="data.active" icon="fa-solid fa-check"/>
                                            <span>
                                                {{ data.active ? 'Activated' : 'Active' }}
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </template>
                </vs-tab> -->
            </vs-tabs>
        </div>

        <!-- CONFIRM LOG OUT -->
        <b-modal id="logout" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
            <div class="float-right">
                <button class="btn btn-close d-flex justify-content-center align-items-center pr-0" @click="$bvModal.hide('logout')"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
            </div>
            <div class="mt-5 text-center mb-3">
                <img src="@/assets/images/icons/data-none.png" alt="sovia-delete">
                <div class="mt-2">
                    <h5 class="font-weight-bold">Are you sure you want</h5>
                    <h5 class="font-weight-bold">to leave?</h5>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <button class="btn btn-secondary text-white mr-2" @click="$bvModal.hide('logout')"><p class="mb-0">No</p></button>
                    <button class="btn btn-primary btn-purple-nb" @click="signOut()"><p class="mx-2 mb-0">Yes</p></button>
                </div>
            </div>
        </b-modal>
        <b-modal id="change-profile" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="lg">
            <div class="d-flex justify-content-between mb-4 mt-2 mx-4">
                <div class="text-left">
                    <h3 class="font-weight-bold">Change Profile</h3>
                </div>
                <div class="text-right">
                    <img src="@/assets/images/icons/close-modal.svg" alt="close" @click="$bvModal.hide('change-profile')" class="cursor-pointer">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col ml-2 px-2">
                    <div class="form-group">
                        <div class="row px-3 justify-content-center">
                            <div class="d-flex">
                                <img v-if="users.picture != null" :src="users.picture" alt="" class="rounded-circle" width="300">
                                <img v-else-if="formData.picture" :src="formData.picture" alt="" class="rounded-circle" width="300">
                                <img v-else src="@/assets/images/img-placeholder.png" alt="placeholder" class="img-fluid rounded-circle" width="300">
                            </div>
                        </div>
                        <div class="row px-3 justify-content-center mt-3">
                            <div class="d-flex justify-content-center">
                                <button type="button" class="btn btn-primary btn-purple" @click="$refs.fileInput1.click()">Choose Photo</button>
                                <input type="file" @change="onFileChange" accept="image/png, image/jpg, image/jpeg" id="fileInput1" ref="fileInput1" class="d-none">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>

        <b-modal id="payment-method" centered no-close-on-backdrop header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="md">
            <div class="d-flex justify-content-between mb-4 mt-2 mx-4">
                <div class="text-left d-flex align-items-center">
                    <p class="title-modal-payment mb-0 mr-2">{{ titleModalPayment }}</p>
                    <span v-if="titleModalPayment == 'Payment Method'" :class="payment.is_used ? 'mr-2 badge-used px-2 d-flex align-items-center' : 'mr-2 badge-noused px-2 d-flex align-items-center'">{{ payment.is_used ? 'Used' : 'Unused' }}</span>
                    <div class="onoffswitch" v-if="titleModalPayment != 'Add Payment Method'">
                        <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" id="myonoffswitch" tabindex="0" :checked="payment.is_used" @change="changeUse()" :disabled="payment.is_used">
                        <label class="onoffswitch-label mb-0" for="myonoffswitch"></label>
                    </div>
                </div>
                <div class="text-right">
                    <img src="@/assets/images/icons/close-modal.svg" alt="close" @click="closeModalPayment()" class="cursor-pointer">
                </div>
            </div>
            <div class="form-row mt-3 px-4">
                <b-form-group id="email-group" class="col-md-12 text-left">
                    <div class="d-flex align-items-center justify-content-between">
                        <label for="email">Email</label>
                        <span class="title-btn-purple mb-2" @click="trigerEditPayment()" v-if="statusBtnEditPayment">{{ titleBtnEditPayment }}</span>
                    </div>
                    <b-form-input :class="statusDisabledPayment ? 'form-control-payment-disabled' : 'form-control-payment'" type="email" v-model="payment.email" :disabled="statusDisabledPayment" id="email" placeholder="Input Your Email"></b-form-input>
                </b-form-group>
                <b-form-group id="card-information" label-for="card-information" class="col-md-12 text-left">
                    <div class="d-flex align-items-center">
                        <label for="card-information mr-3">Card Information</label>
                        <img src="@/assets/images/icons/visa-modal.svg" alt="card" class="ml-2">
                    </div>
                    <b-form-input :class="statusDisabledPayment ? 'form-control-payment-disabled' : 'form-control-payment'" :disabled="statusDisabledPayment" type="text" v-model="payment.card_number" id="card-information" placeholder="1234 XXXX XXXX XXXX"></b-form-input>
                </b-form-group>
                <b-form-group id="exp-group" label-for="exp" class="col-md-6 text-left">
                    <b-form-input :class="statusDisabledPayment ? 'form-control-payment-disabled' : 'form-control-payment'" :disabled="statusDisabledPayment" type="text" v-model="payment.card_date" id="exp" placeholder="MM/YY"></b-form-input>
                </b-form-group>
                <b-form-group id="card-code-group" label-for="exp" class="col-md-6 text-left">
                    <b-form-input :class="statusDisabledPayment ? 'form-control-payment-disabled' : 'form-control-payment'" :disabled="statusDisabledPayment" type="text" v-model="payment.cvv" id="exp" placeholder="CVV"></b-form-input>
                </b-form-group>
                <b-form-group label="Name" id="name-group" label-for="name" class="col-md-12 text-left">
                    <b-form-input :class="statusDisabledPayment ? 'form-control-payment-disabled' : 'form-control-payment'" :disabled="statusDisabledPayment" type="text" v-model="payment.fullname" id="name" placeholder="Input Your Name"></b-form-input>
                </b-form-group>
                <b-form-group label="Address" id="address-group" label-for="address" class="col-md-12 text-left">
                    <b-form-input :class="statusDisabledPayment ? 'form-control-payment-disabled' : 'form-control-payment'" :disabled="statusDisabledPayment" type="text" v-model="payment.address" id="address" placeholder="Input Your Address"></b-form-input>
                </b-form-group>
            </div>
            <div class="d-flex mt-3 px-4 mb-2 align-items-center justify-content-between">
                <span class="title-btn-purple" @click="$bvModal.show('alert-confirm')">{{ titleBtnRemovePayment }}</span>
                <div class="d-flex align-items-center justify-content-between">
                    <button class="btn btn-primary btn-secondary mr-2" @click="closeModalPayment()">Cancel</button>
                    <button class="btn btn-primary btn-purple px-4" @click="savePayment()">Save</button>
                </div>
            </div>
        </b-modal>
        <!-- ALERT FOR DELETE -->
        <b-modal id="alert-confirm" no-close-on-backdrop centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
            <div class="float-right">
                <button class="btn btn-close d-flex justify-content-center align-items-center pr-0" @click="$bvModal.hide('alert-confirm')"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
            </div>
            <div class="mt-5 text-center mb-3">
                <img src="@/assets/images/icons/data-none.png" alt="sovia-delete">
                <div class="mt-2">
                    <h5 class="font-weight-bold">Are you sure?</h5>
                    <h5 class="font-weight-bold">You want to delete this payment card?</h5>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <button class="btn btn-secondary text-white mr-2" @click="closeModalPayment(), $bvModal.hide('alert-confirm')"><p class="mb-0">No</p></button>
                    <button class="btn btn-primary btn-purple-nb" @click="deleteCard(payment._id)"><p class="mx-2 mb-0">Yes!</p></button>
                </div>
            </div>
        </b-modal>
        <b-modal id="alert-payment"  no-close-on-backdrop centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
            <div class="text-center pb-3 pt-1">
                <div class="mt-2">
                    <h5 class="font-weight-bold">{{ textAlert }}</h5>
                </div>
            </div>
        </b-modal>
        <CreatePin name="profile-create-pin-modal" :type="type_modal" title-type="change" @update:type="handleUpdateType" @forgot-pin="handleForgotPin"/>
        <VerificationCode v-on:otp-success="openCreatePin"/>
        <DetailCommunity :community-id="community_id" :data-community="detail_community" @update-community="handleUpdateCommunity" @update-change="handleUpdateChange"/>
        <DowngradeCommunity :community-id="community_id" :list-member="list_dmc" @update-community="handleUpdateCommunity"/>
        <Soca v-if="checkLoad()" />
        <ChooseRole />
        <ConfirmCancelSubscription :planProgress="planProgress" @confirm-cancel="cancelSubscription"/>
        <SendFeedbackCancelSubscription @sendFeedback="sendFeedback"/>
        <CreateOrganization v-model="organization_name" @click:generate-key="handleGenerateKey"/>
        <ConfirmResetApikeys @click:confirm-reset-api="handleResetAPIKey"/>
    </section>
</template>

<script>
import { getProjects } from '@/services/projects/projects.service'
import { getAccountInfo, updateProfile, getCardPaymentMethod, createCardPaymentMethod, deleteCardPaymentMethod, updateCardPaymentMethod, paymentHistory, updateStatusUsedCard } from '@/services/user/user.service'
import { mapActions } from "vuex";
import { createAlert } from "@/helper/alert"
import { subscriptionsStatus } from '@/services/subscriptions/subscriptions.service'
import { newPassword } from "@/services/new-password/new_password.service";
import { AlertUtils } from "@/mixins/AlertUtils";
import { checkStatusPin, requestSetPin } from '@/services/pin/pin.service'
import { getOwnershipCommunity, getMemberCommunity, putCommunity, getDetailCommunity, getListMemberDowngradeCommunity } from '@/services/community/community.service'
import { EventBus } from '@/helper/event-bus.js';
import { getList, getPlanUsageProgress, sendFeedbackCancelPlans, cancelPlans } from "@/services/plans/plans.service.js"
import { topupSwc } from '@/services/subscriptions/subscriptions.service'
import { generateAPIKey, getAPIKEY, regenerateAPIKey } from '@/services/api-public/api-public.service'
import { getRequestDashboard, getUsageAllocationDashboard } from '@/services/api-public/api-public.service'

// import ApiKeys from './Tabs/ApiKeys.vue'
import CreatePin from '@/components/pin/CreatePin'
import VerificationCode from '@/components/pin/VerificationCode'
import DetailCommunity from '@/components/profile/community/DetailCommunity'
import DowngradeCommunity from '@/components/community/modal/DowngradeCommunity'
import ChooseRole from '@/components/modal/ChooseRole'
import ConfirmCancelSubscription from '@/components/modal/ConfirmCancelSubscription'
import SendFeedbackCancelSubscription from '@/components/modal/SendFeedbackCancelSubscription'
import CreateOrganization from '@/components/modal/CreateOrganization'
import ConfirmResetApikeys from '@/components/modal/ConfirmResetApiKey'
import Soca from '@/components/loading/Soca'
import CryptoJS from 'crypto-js';
import moment from 'moment';
// import CustomCard from './Tabs/Card.vue'
// import Chart from './Tabs/Chart.vue'

// import axios from "axios";
// import Swal from 'sweetalert2';

export default {
    mixins: [AlertUtils],
    components : {
        CreatePin,
        VerificationCode,
        DowngradeCommunity,
        DetailCommunity,
        ChooseRole,
        Soca,
        ConfirmCancelSubscription,
        SendFeedbackCancelSubscription,
        // 'api-keys': ApiKeys,
        CreateOrganization,
        ConfirmResetApikeys,
        // CustomCard,
        // Chart
    },
    data(){
        return {
            custom_date: [],
            custom_date_rd: [],
            pickerOptions: {
                disabledDate(time) {
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);

                    return time.getTime() > today.getTime();
                },
            },
            list_filter: [
                {
                    key: 'today',
                    val: this.$t('today')
                },
                {
                    key: '7',
                    val: this.$t('last-7')
                },
                {
                    key: '30',
                    val: this.$t('last-30')
                },
                {
                    key: 'custom',
                    val: this.$t('custom-date')
                },
            ],
            list_filter_api_request: [
                {
                    key: 'days',
                    val: 'Days'
                },
                {
                    key: 'month',
                    val: 'Month'
                },
                {
                    key: 'year',
                    val: 'Year'
                },
            ],
            selected_filter_api_request: 'month',
            subs: {
                multi_voice: 70,
                voice_cloning: 100,
                voice_character: 0
            },
            options_date: '7',
            options_date_rd: '7',
            users:[],
            status_subscriptions: {},
            accountInfo: [],
            perPage: 5,
            currentPage: 1,
            totalData: 0,
            activeTab: 0,
            typeInput1: 'password',
            typeInput2: 'password',
            status_pin: 'Setup PIN',
            type_modal : 'change',
            status_show_password1 : false,
            status_show_password2 : false,
            isLangShow : false,
            textAlert: "",
            ic_eye_1 : 'ic_eye.svg',
            ic_eye_2 : 'ic_eye.svg',
            formData: {
                name: '',
                email : '',
                phone_number : '',
                birth_date : '',
                address : '',
                picture: '',
            },
            visible: false,
            newPassword: '',
            confirmPassword: '',
            colorx: '#6D75F6',
            lang: "English",
            optionsLang:[
                { lang:'English', value:'en' },
                { lang:'Indonesian', value:'id'},
            ],
            payment: {
                email: '',
                card_number: '',
                card_date: '',
                cvv: '',
                fullname: '',
                address: ''
            },
            responseValidate: "",
            showAlert: false,
            filename: "",
            fields_credit_usages_history: [
                {
                    key: "createdAt",
                    label: this.$t('date-time'),
                    thStyle: "background-color: #1F1F1F; color: #8C8C8C; padding-top: 8px; padding-bottom: 8px;",
                },
                {
                    key: "category",
                    label: this.$t('category'),
                    thStyle: "background-color: #1F1F1F; color: #8C8C8C; padding-top: 8px; padding-bottom: 8px;",
                },
                {
                    key: "use_allocation",
                    label: this.$t("credits"),
                    thStyle: "background-color: #1F1F1F; color: #8C8C8C; padding-top: 8px; padding-bottom: 8px;",
                },
            ],
            items_history_credit:[
                // {
                //     createdAt: null,
                //     category: "Mono Voice",
                //     use_allocation: "1 Sec",
                // },
                // {
                //     createdAt: null,
                //     category: "Mono Voice",
                //     use_allocation: "1 Sec",
                // },
                // {
                //     createdAt: null,
                //     category: "Mono Voice",
                //     use_allocation: "1 Sec",
                // },
                // {
                //     createdAt: null,
                //     category: "Mono Voice",
                //     use_allocation: "1 Sec",
                // },
                // {
                //     createdAt: null,
                //     category: "Mono Voice",
                //     use_allocation: "1 Sec",
                // },
                // {
                //     createdAt: null,
                //     category: "Mono Voice",
                //     use_allocation: "1 Sec",
                // },
                // {
                //     createdAt: null,
                //     category: "Mono Voice",
                //     use_allocation: "1 Sec",
                // },
                // {
                //     createdAt: null,
                //     category: "Mono Voice",
                //     use_allocation: "1 Sec",
                // },
                // {
                //     createdAt: null,
                //     category: "Mono Voice",
                //     use_allocation: "1 Sec",
                // },
                // {
                //     createdAt: null,
                //     category: "Mono Voice",
                //     use_allocation: "1 Sec",
                // },
            ],
            fields: [
                {
                    key: "invoice_number",
                    label: this.$t('invoice'),
                    thStyle: "background-color: #1F1F1F; color: #8C8C8C; padding-top: 8px; padding-bottom: 8px;",
                },
                {
                    key: "createdAt",
                    label: this.$t('billing-date'),
                    sortable: true,
                    thStyle: "background-color: #1F1F1F; color: #8C8C8C; padding-top: 8px; padding-bottom: 8px;"
                },
                {
                    key: "payment_status",
                    label: "Status",
                    thStyle: "background-color: #1F1F1F; color: #8C8C8C; padding-top: 8px; padding-bottom: 8px;"
                },
                {
                    key: "final_amount",
                    label: this.$t('amount'),
                    thStyle: "background-color: #1F1F1F; color: #8C8C8C; padding-top: 8px; padding-bottom: 8px;"
                },
                {
                    key: "package_name",
                    label: this.$t('package'),
                    thStyle: "background-color: #1F1F1F; color: #8C8C8C; padding-top: 8px; padding-bottom: 8px;"
                },
                //{
                 //   key: "Action",
                   // thStyle: "background-color: #1F1F1F; color: #8C8C8C; padding-top: 8px; padding-bottom: 8px;"
                //},

            ],
            items: [],
            list_card: [],
            titleModalPayment: "",
            titleBtnEditPayment: "",
            titleBtnRemovePayment: "",
            statusDisabledPayment: false,
            statusBtnEditPayment: true,
            statusDisabledBtnSave: true,
            is_password_secure : false,
            is_password_match : true,
            loading_password : false,
            secure_password : [
                {
                    condition : 'One lowercase character',
                    status : false
                },
                {
                    condition : 'One uppercase character',
                    status : false
                },
                {
                    condition : 'One number',
                    status : false
                },
                {
                    condition : '8 characters minimum',
                    status : false
                },
            ],

            dummyCommunity : [
                {
                    nama : '13'
                }
            ],

            ownership_community : [],
            member_community : [],
            list_dmc : null,
            detail_community : null,
            loading_mc : [],
            loading_oc : false,
            loading_cc : false,
            loading_ga : false,
            loading_gp : false,
            community_active : null,
            community_id : null,

            sccRejected: false,
            isVerified: false,
            load_list_history: false,
            pageHistory: 1,
            totalPageHistory: 0,
            planProgress: null,
            load_to_invoice: false,

            // USAGE DASHBOARD
            cuActive: 'Cummulative',
            chartCUC: {
                chart: {
                    height: 300,
                    type: 'area',
                    toolbar: {
                        show: false
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    colors: ['#6D75F6', '#6D75F6']
                },
                fill: {
                    colors: ['#6D75F6', '#6D75F6']
                },
                grid: {
                    show: true,
                    borderColor: '#585859',
                },
                xaxis: {
                    categories: [],
                    labels: {
                        style: {
                            colors : '#fff'
                        }
                    },
                    tooltip: {
                        enabled: false
                    }
                },
                yaxis: {
                    labels: {
                        style: {
                            colors : [ '#fff' ]
                        }
                    },
                    tooltip: {
                        enabled: false
                    }
                },
                tooltip: {
                    custom: this.customTooltip,
                },
            },

            seriesCUC: [
                {
                    name: '',
                    data : [
                        ['01-11', 0],
                        ['01-12', 30000],
                        ['01-13', 500000],
                        ['01-14', 60000],
                        ['01-15', 700000],
                    ],

                },
            ],

            chartCUD: {
                chart: {
                    height: 300,
                    type: 'bar',
                    toolbar: {
                        show: false
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    colors: ['#585859', '#585859']
                },
                fill: {
                    colors: ['#585859', '#585859']
                },
                grid: {
                    show: true,
                    borderColor: '#585859',
                },
                xaxis: {
                    type: 'datetime',
                    categories: [],
                    labels: {
                        style: {
                            colors : '#fff'
                        }
                    }
                },
                yaxis: {
                    labels: {
                        style: {
                            colors : [ '#fff' ]
                        }
                    }
                },
                tooltip: {
                    custom: this.customTooltip,
                    x: {
                        show: false
                    }
                },
            },

            seriesCUD: [
                {
                    name: '',
                    data : [
                        ['2023-01-11', 0],
                        ['2023-01-12', 300],
                        ['2023-01-13', 20],
                        ['2023-01-14', 100],
                        ['2023-01-15', 80],
                    ],

                },
            ],

            chartAPI: {
                chart: {
                    height: 200,
                    type: 'bar',
                    toolbar: {
                        show: false
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    colors: ['#585859', '#585859']
                },
                fill: {
                    colors: ['#585859', '#585859']
                },
                grid: {
                    show: true,
                    borderColor: '#585859',
                },
                xaxis: {
                    type: 'datetime',
                    categories: [],
                    labels: {
                        style: {
                            colors : '#fff'
                        }
                    }
                },
                yaxis: {
                    labels: {
                        style: {
                            colors : [ '#fff' ]
                        }
                    }
                },
                tooltip: {
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        const xaxis = w.globals.labels[dataPointIndex]
                        return '<div class="p-2 d-flex flex-column ud--tooltip-custom">' +
                        '<span class="ud--tooltip-custom__text-title">' + xaxis + '</span>' +
                        '<span class="ud--tooltip-custom__text">' + series[seriesIndex][dataPointIndex] + ' Total Request</span>' +
                        '</div>'
                    },
                    x: {
                        show: false
                    }
                },
            },

            seriesAPI: [
                {
                    name: '',
                    data : [
                        ['2023-01-11', 0],
                        ['2023-01-12', 300],
                        ['2023-01-13', 20],
                        ['2023-01-14', 100],
                        ['2023-01-15', 80],
                    ],

                },
            ],

            filterCU: this.getDefaultMonthYear(),
            isGetReqeuestDashboard: false,

            organization_name: null,
            credentialsAPI: {},
            isGenerateKey: false,
            isGetAPIKEY: false,
        }
    },

    computed: {
        totalPages() {
            let totalPage = Math.ceil(this.rows / this.perPage);
            return totalPage;
        },
        rows() {
            return this.totalData;
        },

    },

    mounted(){

        // $("section.profile").parent().addClass("m-0");
        this.getProject()
        this.getAccount()
        this.checkPin()
        this.getLang();
        this.getCardPayment();
        this.getStatusSubscriptions();
        this.getPaymentHistory();
        this.getCommunityOwner();
        this.getCommunityMember();
        this.getListHistoryCredit();
        this.getPlanProgress();
        this.handleGetDataAPIKEY();
        this.getDataRequestDashboard();
        this.getDataUsageAllocationDashboard();

        if(localStorage.getItem('users') == undefined){
            this.$router.push({ name : 'My-Quiz'});
        }

        if(this.activeTab === 1){
            setTimeout(() => {
                const tbodyElement = this.$refs.tableHistory.$el;
                tbodyElement.addEventListener('scroll', this.handleScroll);
            }, 100);
        }else{
            const tbodyElement = this.$refs.tableHistory.$el;
            tbodyElement.removeEventListener('scroll', this.handleScroll);
        }

        // tbodyElement.addEventListener('scroll', this.handleScroll);

        document.addEventListener('click', this.closeLangWhenClickOutside);
    },

    beforeDestroy() {
        document.removeEventListener('click', this.closeLangWhenClickOutside);

        const tbodyElement = this.$refs.tableHistory.$el;
        tbodyElement.removeEventListener('scroll', this.handleScroll);
    },

    created() {
        if(this.$route.query.activeTab == 'community') {
            this.activeTab = 2
        }
    },

    watch: {
        currentPage() {
            var vm = this
            vm.getPaymentHistory()
        },
        options_date(val){
            console.log(val)
            if(val != 'custom'){
                this.pageHistory = 1;
                this.items_history_credit = [];
                this.totalPageHistory = 0;
                this.getListHistoryCredit();
            }
        },
        custom_date(val){
            console.log(val)
            this.pageHistory = 1;
            this.totalPageHistory = 0;
            this.items_history_credit = [];
            this.getListHistoryCredit();
        },
        activeTab(val){
            console.log(val)
            if(val === 1){
                setTimeout(() => {
                    const tbodyElement = this.$refs.tableHistory.$el;
                    tbodyElement.addEventListener('scroll', this.handleScroll);
                }, 100);
            } else if(val != 2) {
                const tbodyElement = this.$refs.tableHistory.$el;
                tbodyElement.removeEventListener('scroll', this.handleScroll);
            }
        },
        options_date_rd(val){
            if(val != 'custom'){
                this.getDataRequestDashboard();
            }
        },
        custom_date_rd(val){
            if(val) {
                this.getDataRequestDashboard();
            }
        },
        filterCU(val) {
            if(val) {
                this.getDataUsageAllocationDashboard();
            }
        },
        cuActive(val) {
            if(val) {
                this.getDataUsageAllocationDashboard();
            }
        }
    },

    methods: {
        ...mapActions(["logout"]),
        printPlanProgress(progress){
        
            if(progress.minutes > 0 && progress.seconds > 0){
                return `${progress.minutes} ${this.$t('minutes')} ${progress.seconds} ${this.$t('seconds')} `
            }else if(progress.minutes > 0 && progress.seconds < 1){
                return `${progress.minutes} ${this.$t('minutes')}`
            }else if(progress.minutes < 1 && progress.seconds > 0){
                return `${progress.seconds} ${this.$t('seconds')}`
            }else{
                return 0;
            }
        },
        navigation(nav){
            window.open(nav);
        },
        createInvoice(){
            this.load_to_invoice = true;

            let formData = {
                package_id : this.planProgress.package_subscriptions_id,
                community_id : null
            }

            topupSwc(formData)
            .then((response) => {
                if(response.status == true){
                    this.load_to_invoice = false;
                    this.$router.push({ name : 'Subscriptions', query : { id : response.data.subscription_id } })
                }
            })
            .catch((err) => {
                this.load_to_invoice = false;
                console.log(err)
            })
        },
        cancelSubscription(){
            cancelPlans().then((res)=>{
                    if(res.status){
                        this.$bvModal.hide('confirm--cancel-subscription');
                        this.$bvModal.show('sendfeedback--cancel-subscription');
                        this.getPlanProgress();
                    }else{
                        this.alertFailIcon(res.message)
                    }
                }).catch((res)=>{
                    console.log(res)
                    this.alertFailIcon("Error Cancel Subscription!")
                });
        },
        sendFeedback(msg){
            if(msg != null || msg != ''){
                let data = {
                    feedback: msg
                }
                sendFeedbackCancelPlans(data).then((res)=>{
                    if(res.status){
                        this.$bvModal.hide('sendfeedback--cancel-subscription');
                        this.alertSuccess('Subscription Canceled')

                    }else{
                        this.alertFailIcon(res.message)
                    }
                }).catch((res)=>{
                    // this.alertFailIcon("Error Send Feedback!")
                    console.log(res)
                });
            }
        },
        handleScroll(event){
            console.log("-------------");
            console.log("SCROLL TOP : "+event.target.scrollTop);
            console.log("CLIENT HEIGHT : "+event.target.clientHeight);
            console.log("SCROLL TOP + CLIENT HEIGHT  : "+(event.target.scrollTop + event.target.clientHeight));
            console.log("CLIENT HEIGHT : "+event.target.scrollHeight);
            console.log("-------------");
            console.log(this.pageHistory);
            if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight && this.pageHistory <= this.totalPageHistory) {
                if (!this.load_list_history) {
                  this.getListHistoryCredit();
                }
            }
        },

        customTooltip({ series, seriesIndex, dataPointIndex, w }) {
            const cuActive = this.cuActive; // Ambil nilai cuActive di sini
            const label = cuActive  == 'Daily' ? w.globals.labels[dataPointIndex] : this.filterCU + '-' + w.globals.labels[dataPointIndex]
            const date = new Date(label);
            const formattedDate = date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short' });
            return '<div class="p-2 d-flex flex-column ud--tooltip-custom">' +
            '<span class="ud--tooltip-custom__text-title">' + formattedDate + '</span>' +
            '<span class="ud--tooltip-custom__text">' + cuActive + ' Usage : ' + series[seriesIndex][dataPointIndex] + ' characters</span>' +
            '</div>';
        },

        convertCurrency(amount){
            if (amount >= 1000) {
                return (amount / 1000).toFixed() + "K";
            }
            return amount.toString();
        },

        getPlanProgress(){
            getPlanUsageProgress().then((res)=>{
                if(res.status){
                    this.planProgress = res.data;
                }else{
                    this.planProgress = null;
                }
            }).catch((err)=>{
                console.log(err)
            })
        },

        getDateAndYearOnly(data){
            const date = moment(data).utc();
            return date.format("MMM D, YYYY");
        },
        getDate(data){
            const date = moment(data).utc(); // Pastikan waktu dalam UTC
            return date.format("MMM D, YYYY, HH:mm:ss");
        },

        getListHistoryCredit(){
            this.load_list_history = true;
            let data = {
                page: this.pageHistory,
                limit: 10,
                options: this.options_date,
                start_date: this.custom_date ? this.custom_date[0] : null,
                end_date: this.custom_date ? this.custom_date[1] : null
            }
            getList(data).then((res)=>{
                if(res.status){

                    this.items_history_credit = this.items_history_credit.concat(res.data);
                    this.pageHistory++;
                    this.totalPageHistory = parseInt(res.totalPage);
                }else{
                    this.pageHistory = 1;
                    this.totalPageHistory = 0;
                    this.items_history_credit = [];
                }
                this.load_list_history = false;
            }).catch((err)=>{
                console.log(err)
                this.load_list_history = false;
            })
        },

        closeLangWhenClickOutside(e) {
            if (this.isLangShow && !this.$refs.listLang.contains(e.target)) {
                this.isLangShow = false;
            }
        },

        checkLoad(){
            return this.loading_oc || this.loading_mc || this.loading_cc || this.loading_ga || this.loading_gp
        },

        getCommunityOwner() {
            this.loading_oc = true

            getOwnershipCommunity()
            .then((response) => {
                if(response.status) {
                    this.ownership_community = response.data
                    this.loading_oc = false
                } else {
                    this.ownership_community = []
                    this.loading_oc = false
                }
            })
            .catch((err) => {
                console.log(err)
                this.loading_oc = false
            })
        },

        getCommunityMember() {
            this.loading_mc = true

            getMemberCommunity()
            .then((response) => {
                if(response.status) {
                    this.member_community = response.data
                    this.loading_mc = false
                } else {
                    this.member_community = []
                    this.loading_mc = false
                }
            })
            .catch((err) => {
                console.log(err)
                this.loading_mc = false
            })
        },

        detailCommunity(id, type, downgrade){
            this.community_id = id
            if(!downgrade) {
                getDetailCommunity(id)
                .then((response) => {
                    if(response.status) {
                        this.detail_community = response.data
                        if(!type) {
                            this.$bvModal.show('detail-community-modal')
                        }
                    }
                })
            } else {
                getListMemberDowngradeCommunity(id)
                .then((response) => {
                    if(response.status) {
                        this.list_dmc = response.data
                        this.$bvModal.show('dgc-modal')
                    }
                })
            }
        },

        changeCommunity(id) {
            this.loading_cc = true

            let formData = {
                community_id : id
            }

            putCommunity(formData)
            .then((response) => {
                if(response.status) {
                    this.loading_cc = false
                    this.getCommunityOwner()
                    this.getCommunityMember()
                    EventBus.$emit('triggerSidebarMethod');
                    // this.$router.replace({ query: { activeTab: 'community' } })
                    // this.$router.go(0)
                }
            })
        },

        activeCard(i) {
            this.community_active = i
        },

        changeUse(){
            if(this.payment.is_used){
                this.payment.is_used = false;
                updateStatusUsedCard(this.payment._id).then((response)=>{
                    this.textAlert = "Your card has been used";
                    this.$bvModal.show('alert-payment');

                    setTimeout(()=>{
                        this.$bvModal.hide('alert-payment');
                    }, 2000)

                    this.getCardPayment()
                    console.log(response)
                }).catch(error=>{
                    console.log(error)
                })
            }else{
                this.payment.is_used = true;
                updateStatusUsedCard(this.payment._id).then((response)=>{

                    this.textAlert = "Your card has been used";

                    this.$bvModal.show('alert-payment');

                    setTimeout(()=>{
                        this.$bvModal.hide('alert-payment');
                    }, 2000)
                    this.getCardPayment()
                    console.log(response)
                }).catch(error=>{
                    console.log(error)
                })
            }
        },

        handleUpdateCommunity() {
            this.getCommunityOwner()
            this.getCommunityMember()
        },

        handleUpdateChange(val){
            this.detailCommunity(val, 'cancel')
        },

        getStatusSubscriptions(){


            subscriptionsStatus()
            .then((response) =>{
                if(response.status == true ){
                    this.status_subscriptions = response.data
                    // this,
                } else if(response.status == false && response.message == 'Allocations Not Found') {
                    // this.
                } else {

                    // localStorage.clear();
                    // this.$router.push({ name : 'SignIn' })
                    console.log(response)
                }
            })
        },
        getBillingDate(value){
            let result = "";
            if(value != null){
                if(value.includes("-")){
                    let date = value.split("-");
                    let year = date[0];
                    let month = date[1];

                    let newMonth = "";

                    switch(month){
                        case "01":
                            newMonth = "Jan";
                            break;
                        case "02":
                            newMonth = "Feb";
                            break;
                        case "03":
                            newMonth = "Mar";
                            break;
                        case "04":
                            newMonth = "Apr";
                            break;
                        case "05":
                            newMonth = "May";
                            break;
                        case "06":
                            newMonth = "Jun";
                            break;
                        case "07":
                            newMonth = "Jul";
                            break;
                        case "08":
                            newMonth = "Aug";
                            break;
                        case "09":
                            newMonth = "Sep";
                            break;
                        case "10":
                            newMonth = "Oct";
                            break;
                        case "11":
                            newMonth = "Nov";
                            break;
                        case "12":
                            newMonth = "Dec";
                            break;
                        default:
                            break;
                    }

                    let newDate = date[2].split("T");

                    result = newMonth+" "+newDate[0]+", "+year;
                }
            }
            return result;
        },
        getPaymentHistory(){
            paymentHistory(this.currentPage, this.perPage).then(response =>{
                if(response.status){
                    this.items = response.data
                    this.totalData = response.totalData
                    this.currentPage = parseInt(response.page)
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        deleteCard(id){
            this.$bvModal.hide('payment-method');
            deleteCardPaymentMethod(id).then((response)=>{
                this.$bvModal.hide('alert-confirm')
                if(response.status){
                    // Swal.fire(
                    //     'Deleted!',
                    //     'Your Payment Card has been deleted.',
                    //     'success'
                    // )
                    this.textAlert = "Your Payment Card has been deleted.";

                    this.$bvModal.show('alert-payment');

                    setTimeout(()=>{
                        this.$bvModal.hide('alert-payment');
                    }, 2000)

                    this.getCardPayment();
                }else{
                    this.$bvModal.hide('alert-confirm')
                    createAlert('error', 'Failed!', response.message);
                }
                    this.$vs.loading.close();
            }).catch((error)=>{
                    this.$vs.loading.close();
                    createAlert('error', 'Failed!', 'Error Deleted Payment Card!');
                    console.log(error);
            })
        },
        showCard(index){
            this.payment = {
                email: '',
                card_number: '',
                card_date: '',
                cvv: '',
                fullname: '',
                address: ''
            };
            this.payment = this.list_card[index];

            this.showModalPayment('detail')
        },
        trigerEditPayment(){
            this.statusDisabledPayment = false;
            this.statusBtnEditPayment = false;
        },
        closeModalPayment(){
            this.$bvModal.hide('payment-method');
            this.statusDisabledPayment = false;
            this.statusBtnEditPayment = true;
            this.titleModalPayment = "";
            this.titleBtnEditPayment = "";
            this.titleBtnRemovePayment = "";
            this.payment = {
                email: '',
                card_number: '',
                card_date: '',
                cvv: '',
                fullname: '',
                address: ''
            };
        },
        savePayment(){
            if(this.payment.email == '' || this.payment.email == null){
                // createAlert('warning', 'Warning!', 'Email cannot be empty!');
                this.textAlert = "Email cannot be empty!";
                    this.$bvModal.show('alert-payment');

                setTimeout(()=>{
                        this.$bvModal.hide('alert-payment');
                }, 2000)
                return false;
            }else if(this.payment.card_number == '' || this.payment.card_number == null){
                // createAlert('warning', 'Warning!', 'Card Information cannot be empty!');
                this.textAlert = "Card Information cannot be empty!";
                    this.$bvModal.show('alert-payment');

                setTimeout(()=>{
                        this.$bvModal.hide('alert-payment');
                }, 2000)
                return false;
            }else if(this.payment.card_date == '' || this.payment.card_date == null){
                // createAlert('warning', 'Warning!', 'Card Date (MM/YY) cannot be empty!');
                this.textAlert = "Card Date (MM/YY) cannot be empty!";
                    this.$bvModal.show('alert-payment');

                setTimeout(()=>{
                        this.$bvModal.hide('alert-payment');
                }, 2000)

                return false;
            }else if(this.payment.cvv == '' || this.payment.cvv == null){
                // createAlert('warning', 'Warning!', 'CVV cannot be empty!');
                this.textAlert = "CVV cannot be empty!";
                    this.$bvModal.show('alert-payment');

                setTimeout(()=>{
                        this.$bvModal.hide('alert-payment');
                }, 2000)
                return false;
            }else if(this.payment.fullname == '' || this.payment.fullname == null){
                // createAlert('warning', 'Warning!', 'Name cannot be empty!');
                this.textAlert = "Name cannot be empty!";
                    this.$bvModal.show('alert-payment');

                setTimeout(()=>{
                        this.$bvModal.hide('alert-payment');
                }, 2000)
                return false;
            }else if(this.payment.address == '' || this.payment.address == null){
                // createAlert('warning', 'Warning!', 'Address cannot be empty!');
                this.textAlert = "Address cannot be empty!";
                    this.$bvModal.show('alert-payment');

                setTimeout(()=>{
                        this.$bvModal.hide('alert-payment');
                }, 2000)
                return false;
            }

            this.$bvModal.hide('payment-method');
            if(this.titleModalPayment == 'Add Payment Method'){
                createCardPaymentMethod(this.payment).then((response)=>{
                if(response.status){
                    // const Toast = Swal.mixin({
                    //     toast: true,
                    //     position: "top-end",
                    //     showConfirmButton: false,
                    //     timer: 3000,
                    //     timerProgressBar: true,
                    //     onOpen: toast => {
                    //     toast.addEventListener("mouseenter", Swal.stopTimer);
                    //     toast.addEventListener("mouseleave", Swal.resumeTimer);
                    //     }
                    //     });
                    //     Toast.fire({
                    //         icon: "success",
                    //         title: "Payment Card successfully added!"
                    //     });
                    //     this.getCardPayment();
                    this.textAlert = "Payment Card successfully added!";
                    this.$bvModal.show('alert-payment');

                    setTimeout(()=>{
                            this.$bvModal.hide('alert-payment');
                    }, 2000)

                    }else{
                        createAlert('error', 'Failed!', response.message);
                    }
                    this.getCardPayment();
                    this.$vs.loading.close();
                }).catch((error)=>{
                    this.$vs.loading.close();
                    console.log(error);
                })
            }else{
                updateCardPaymentMethod(this.payment).then((response)=>{
                if(response.status){
                    // const Toast = Swal.mixin({
                    //     toast: true,
                    //     position: "top-end",
                    //     showConfirmButton: false,
                    //     timer: 3000,
                    //     timerProgressBar: true,
                    //     onOpen: toast => {
                    //     toast.addEventListener("mouseenter", Swal.stopTimer);
                    //     toast.addEventListener("mouseleave", Swal.resumeTimer);
                    //     }
                    //     });
                    //     Toast.fire({
                    //         icon: "success",
                    //         title: "Payment Card successfully updated!"
                    //     });
                        this.textAlert = "Payment Card successfully updated!";
                        this.$bvModal.show('alert-payment');

                        setTimeout(()=>{
                                this.$bvModal.hide('alert-payment');
                        }, 2000)
                        this.getCardPayment();
                    }else{
                        createAlert('error', 'Failed!', response.message);
                    }
                    this.$vs.loading.close();
                }).catch((error)=>{
                    this.$vs.loading.close();
                    console.log(error);
                })
            }
        },
        showModalPayment(tipe){
            switch(tipe){
                case 'add':
                    this.payment = {};
                    this.payment = {
                        email: '',
                        card_number: '',
                        card_date: '',
                        cvv: '',
                        fullname: '',
                        address: ''
                    };
                    this.$bvModal.show('payment-method');
                    this.titleModalPayment = "Add Payment Method";
                    this.titleBtnEditPayment = "";
                    this.titleBtnRemovePayment = "";
                    this.statusDisabledPayment = false;
                    console.log(this.payment)
                    break;
                case 'detail':
                    this.$bvModal.show('payment-method');
                    this.titleModalPayment = "Payment Method";
                    this.titleBtnEditPayment = "Edit Payment Method";
                    this.titleBtnRemovePayment = "Remove Payment Method";
                    this.statusDisabledPayment = true;
                    break;
                default:
                    break;
            }
        },
        formatPrice(value) {
            let theString = value.toString();

            let cekMinus = theString.indexOf("-");
            if (cekMinus != -1) {
                let newVal = theString.replace("-", "");
                let val = (newVal / 1).toFixed(2).replace(".", ",");
                return "Rp. -" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            } else {
                let val = (value / 1).toFixed(2).replace(".", ",");
                return "Rp. " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            }
        },

        showPasswordNewPass(){
            if(this.status_show_password1){
                this.status_show_password1 = false
                this.ic_eye_1 = 'ic_eye.svg'
                this.typeInput1 = 'password'
            } else {
                this.status_show_password1 = true
                this.ic_eye_1 = 'ic_eye_slash.svg'
                this.typeInput1 = 'text'
            }
        },
        getCardPayment(){
            getCardPaymentMethod().then((response)=>{
                if(response.status){
                    this.list_card = response.data;
                }
                this.$vs.loading.close();
            }).catch((error)=>{
                this.$vs.loading.close();
                console.log(error)
            })
        },
        showPasswordConfirmPass(){
            if(this.status_show_password2){
                this.status_show_password2 = false
                this.ic_eye_2 = 'ic_eye.svg'
                this.typeInput2 = 'password'
            } else {
                this.status_show_password2 = true
                this.ic_eye_2 = 'ic_eye_slash.svg'
                this.typeInput2 = 'text'
            }
        },

        onFileChange(e) {
            // get index
            var id = e.target.id;
            id = id.substr(id.length-1, 1);

            const file = e.target.files[0];

            switch (id) {
                case '1':
                    this.formData.picture = URL.createObjectURL(file);
                    this.convertImage(file, 'file')
                    break;

                default:
                    break;
            }
        },
        convertImage(file, form) {
            var vm = this;
            var reader = new FileReader();
            reader.onloadend = function () {
                switch (form) {
                    case 'file':
                        vm.formData.picture = reader.result
                        break;
                    default:
                        break;
                }
            };
            reader.readAsDataURL(file);
        },


        setSideBar() {
            this.$store.commit("IS_SIDEBAR_ACTIVE", false);
        },

        getLang(){
            let lang = localStorage.getItem('lang');

            if(lang != null){

                switch(lang){
                    case 'en':
                        this.lang = "English";
                        break;
                    case 'id':
                        this.lang = "Indonesian";
                        break;
                }

            }
        },

        changeLang(item){
            localStorage.setItem('lang', item);

            let lang = localStorage.getItem('lang');

            if(lang != null){

                switch(lang){
                    case 'en':
                        this.lang = "English";
                        break;
                    case 'id':
                        this.lang = "Indonesian";
                        break;
                }
            }
        },

        getProject() {
            this.loading_gp = true
            getProjects()
            .then((response) => {
                if(response.status && response.status == true){
                    this.users = response.data;
                    this.formData.name = response.data.name
                    this.formData.email = response.data.email
                    this.formData.phone_number = response.data.phone_number ? CryptoJS.AES.decrypt(response.data.phone_number, '100%sukseS!').toString(CryptoJS.enc.Utf8) : null;
                    this.formData.birth_date = response.data.birth_date ? CryptoJS.AES.decrypt(response.data.birth_date, '100%sukseS!').toString(CryptoJS.enc.Utf8) : null;
                    this.formData.address = response.data.address ? CryptoJS.AES.decrypt(response.data.address, '100%sukseS!').toString(CryptoJS.enc.Utf8) : null;

                    // try {
                    //     const response = await axios.get(this.users.picture)
                    //     this.users.picture = response.data
                    // } catch (error) {
                    //     this.users.picture = require('../../../assets/images/img-placeholder.png');
                    //     // this.users.picture = "https://ui-avatars.com/api/?background=405DC3&color=fff&name="+this.users.name;
                    // }

                    this.$vs.loading.close();
                    this.loading_gp = false
                }else{
                    this.$vs.loading.close();
                    this.loading_gp = false
                }
            }).catch((err) => {
                this.$vs.loading.close();
                this.loading_gp = false
                console.log("data err", err)
            });
        },

        getAccount(){
            this.loading_ga = true;
           getAccountInfo()
           .then((response) => {
                if(response.status && response.status == true){
                    this.accountInfo = response.data
                    this.loading_ga = false
                } else {
                    this.loading_ga = false
                }
           })
        },

        signOut(){
            this.logout().then(() => {
                this.$router.push({ name : 'SignIn' })
            })
        },

        cancel(){
            this.getProject()
        },

        updateAccount(){

            let formData = {
                phone_number : this.formData.phone_number,
                birth_date : this.formData.birth_date,
                address : this.formData.address,
                name : this.formData.name
            }

            updateProfile(formData)
            .then((response) => {
                if (response.status && response.status == true){
                    this.getProject()
                    this.alertSuccess('Successfully updated.')
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            })
        },

        formatScore(value){
            if(value){
                var toString = value.toString()
                var valLength = toString.length;

                if(valLength >= 7){
                    return toString.slice(0,1) + "M"
                } else if(valLength >= 6){
                    return toString.slice(0,3) + "k"
                } else if(valLength >= 5){
                    if(toString.slice(2,3) != "0"){
                        return toString.slice(0,2) + "," + toString.slice(2,3) + "k"
                    } else {
                        return toString.slice(0,2) + "k"
                    }
                } else if (valLength >= 4){
                    if(toString.slice(1,2) != "0"){
                        return toString.slice(0,1) + "," + toString.slice(1,2) + "k"
                    } else {
                        return toString.slice(0,1) + "k"
                    }
                } else {
                    return value
                }
            } else {
                return 0;
            }
        },

        checkPasswordStrength() {
            const hasLowerCase = /[a-z]/.test(this.newPassword);
            const hasUpperCase = /[A-Z]/.test(this.newPassword);
            const hasNumber = /\d/.test(this.newPassword);
            const hasMinimumLength = this.newPassword.length >= 8;

            this.secure_password.forEach((condition, index) => {
                switch (index) {
                    case 0:
                        condition.status = hasLowerCase;
                        break;
                    case 1:
                        condition.status = hasUpperCase;
                        break;
                    case 2:
                        condition.status = hasNumber;
                        break;
                    case 3:
                        condition.status = hasMinimumLength;
                        break;
                    default:
                        break;
                }
            });

            if(hasLowerCase && hasUpperCase && hasNumber && hasMinimumLength) {this.is_password_secure = true} else {this.is_password_secure = false}
        },

        postNewPassword(){
            if(this.is_password_secure){
                if(this.newPassword === this.confirmPassword){
                    this.is_password_match = true;
                    this.loading_password = true;

                    let formData = {
                        newPassword : this.newPassword,
                        confirmPassword : this.confirmPassword
                    }

                    newPassword(formData)
                    .then((response) => {
                        if(response.status == true) {
                            this.loading_password = false
                            this.visible = false
                            this.newPassword = null
                            this.confirmPassword = null
                            this.alertSuccess('Your password has been changed.')
                        } else {
                            this.loading_password = false
                            this.alertFailIcon('Your password failed to change.')
                        }
                    })
                } else {
                    this.is_password_match = false;
                }
            }
        },

        checkPin() {
            checkStatusPin()
            .then((response) => {
                if(response.status) {
                    this.status_pin = 'Change PIN'
                    this.type_modal = 'change'
                } else {
                    this.status_pin = 'Setup PIN'
                    this.type_modal = 'create'
                }
            })
        },

        actionPin() {
            if(this.status_pin == 'Change PIN') {
                this.$bvModal.show('profile-create-pin-modal')
            } else {
                this.requestPin()
            }
        },

        handleUpdateType(updatedType) {
            if(updatedType != 'checkPin') {
                this.type_modal = updatedType;
            } else {
                this.checkPin()
            }
        },

        requestPin(){
			requestSetPin()
			.then((response) => {
				if(response.status) {
					this.$bvModal.show('verif-code-modal')
				} else {
                    if(response.response?.response?.status == 429) {
                        this.alertFail("Too many requests, please try again later.")
                    }
                }
			})
		},

        openCreatePin() {
			this.$bvModal.hide('verif-code-modal')
            this.type_modal = 'create'
			this.$bvModal.show('profile-create-pin-modal')
		},

        handleForgotPin(){
			this.$bvModal.hide('profile-create-pin-modal')
            this.requestPin()
        },

        phoneNumberState(){
            if(this.formData.phone_number) {
                return this.formData.phone_number.toString().length > 6 && this.formData.phone_number.toString().length < 30
            } else {
                return false
            }
        },

        fullNameState(){
            return this.formData.name != null && this.formData.name != ''
        },

        truncatedText(text) {
            if(text) {
                let textString = text.toString()
                if(textString.length > 5) {
                    return textString.substring(0, 5) + "...";
                } else {
                    return textString
                }
            }
        },

        handleRequestNow() {
            this.$bvModal.show('choose-role-modal')
        },

        handleGenerateKey() {
            this.isGenerateKey = true
            this.$bvModal.hide('create-organization')
            let formData = {
                organization_name : this.organization_name
            }

            generateAPIKey(formData)
            .then((response) => {
                if(response.status) {
                    this.handleGetDataAPIKEY()
                }
                this.isGenerateKey = false
            })
            .catch(() => {
                this.isGenerateKey = false
            })
        },

        handleGetDataAPIKEY(){
            this.isGetAPIKEY = true
            getAPIKEY()
            .then((response) => {
                if(response.status) {
                    this.credentialsAPI = response.data
                }
                this.isGetAPIKEY = false
            })
            .catch(() => {
                this.isGetAPIKEY = false
            })
        },
        handleResetAPIKey() {
            regenerateAPIKey()
            .then((response) => {
                if(response.status) {
                    this.alertSuccess('API key successfully re-generated')
                    this.handleGetDataAPIKEY()
                }
                this.$bvModal.hide('confirm-reset-apikey')
            })
        },
        getDataRequestDashboard() {
            this.isGetReqeuestDashboard = true
            let query = {
				options: this.options_date_rd,
                start_date: this.custom_date_rd && this.custom_date_rd.length > 0 ? this.custom_date_rd[0] : null,
                end_date: this.custom_date_rd && this.custom_date_rd.length > 0 ? this.custom_date_rd[1] : null
			}

            this.seriesAPI[0].data = []

            getRequestDashboard(query)
            .then((response) => {
                if(response.status) {
                    if(Number.isInteger(response.data[0][0])) {
                        let series = [];
                        let data = [];
                        response.data.forEach((item) => {
                            data.push(this.convertToClock(item[0]));
                            series.push(item[1]);
                        });

                        this.chartAPI = {
                            ...this.chartAPI,
                            ... {
                                xaxis: {
                                    categories : data
                                }
                            }
                        }
                        this.seriesAPI[0].data = series
                    } else {
                        let series = [];
                        let data = [];
                        response.data.forEach((item) => {
                            data.push(this.formatDateMMDD(item[0]));
                            series.push(item[1]);
                        });

                        this.chartAPI = {
                            ...this.chartAPI,
                            ... {
                                xaxis: {
                                    categories : data.reverse()
                                }
                            }
                        }

                        this.seriesAPI[0].data = series.reverse()
                    }
                }
                this.isGetReqeuestDashboard = false
            })
        },
        convertToClock(hour){
           if (hour >= 0 && hour <= 9) {
                return `0${hour}:00`;
            } else {
                return `${hour}:00`;
            }
        },
        formatDateMMDD(dateString) {
            const options = { day: "numeric", month: "short" };
            const dateObject = new Date(dateString);
            return dateObject.toLocaleDateString("id-ID", options);
        },
        getDefaultMonthYear() {
            const today = new Date();
            const year = today.getFullYear();
            const month = today.getMonth() + 1; // Bulan dimulai dari 0, sehingga perlu ditambahkan 1
            return `${year}-${month.toString().padStart(2, '0')}`;
        },
        getDataUsageAllocationDashboard() {
            this.isGetReqeuestDashboard = true
            let splitedDate = this.filterCU ? this.filterCU.split("-") : this.getDefaultMonthYear().split("-")

            let query = {
				month: splitedDate[1],
                year: splitedDate[0],
                calculate: this.cuActive.toLowerCase()
			}

            this.seriesCUC[0].data = []
            this.seriesCUD[0].data = []

            getUsageAllocationDashboard(query)
            .then((response) => {
                if(response.status) {
                    if(Number.isInteger(response.data[0][0])) {
                        let series = [];
                        let data = [];
                        response.data.forEach((item) => {
                            data.push(this.convertToClock(item[0]));
                            series.push(item[1]);
                        });

                        this.chartCUC = {
                            ...this.chartCUC,
                            ... {
                                xaxis: {
                                    categories : data,
                                },
                            }
                        }

                        this.chartCUD = {
                            ...this.chartCUD,
                            ... {
                                xaxis: {
                                    categories : data
                                }
                            }
                        }
                        this.seriesCUC[0].data = series
                        this.seriesCUD[0].data = series
                    } else {
                        let series = [];
                        let data = [];
                        response.data.forEach((item) => {
                            data.push(item[0]);
                            series.push(item[1]);
                        });

                        this.chartCUC = {
                            ...this.chartCUC,
                            ... {
                                xaxis: {
                                    categories : data,
                                    tooltip: {
                                        enabled: false
                                    }
                                },
                                tooltip: {
                                    custom: this.customTooltip,
                                },
                            }
                        }

                        this.chartCUD = {
                            ...this.chartCUD,
                            ... {
                                xaxis: {
                                    categories : data
                                }
                            }
                        }
                        this.seriesCUC[0].data = series
                        this.seriesCUD[0].data = series
                    }
                }
                this.isGetReqeuestDashboard = false
            })
        },
    }
}
</script>

<style>
    .ud--tooltip-custom {
        border-radius: 4px;
        background-color: #2D2D2D;
        border-color: #2D2D2D;
    }

    .ud--tooltip-custom__text-title {
        color: #FFF;
        font-family: Avenir Next;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .ud--tooltip-custom__text {
        color: #6D75F6;
        font-family: Avenir Next;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .cc--gap .apexcharts-tooltip.apexcharts-theme-light {
        border: 1px solid #2D2D2D;
        background: none;
    }

    .cc--gap .apexcharts-xcrosshairs.apexcharts-active, .cc--gap .apexcharts-ycrosshairs.apexcharts-active {
        opacity: 0;
    }

    .cc--gap .apexcharts-bar-area:hover {
        fill: #6D75F6;
        filter: none !important;
        /* opacity: ; */
    }

    #payment-method .modal-content{
        background-color: #1F1F1F;
        color: white;
    }
    #change-profile .modal-content{
        background-color: #1F1F1F;
        color: white;
    }
    #logout .modal-content{
        background-color: #1F1F1F;
        color: white;
    }
    #alert-confirm .modal-content{
        background-color: #1F1F1F;
        color: white;
    }
    #alert-payment .modal-content{
        background-color: #1F1F1F;
        color: white;
    }
    .progress .bg-primary {
        background-color: #6D75F6 !important;
    }

    .btn-primary:hover{

    }

    .user-profile .progress{
        background-color: #2D2D2D;
    }

    /* select */
    .vs-select--options .vs-select--item.activex{
        background: rgba(109, 117, 246, 0.1) !important;
        color: #545EED !important;
    }
    .vs-select--options .vs-select--item:hover {
        background: rgba(109, 117, 246, 0.1);
        border-radius: 3px;
        color: #545EED !important;
    }
    .vs-select--options .vs-select--item {
        color: #fff;
        border-top: 1px solid #1F1F1F;
        border-bottom: 1px solid #1F1F1F;
    }
    .vs-select--options{
        background: #1F1F1F !important;
    }

    .select--filter .select--filter .vs-select--label, .select--filter .select--filter .vs-select--label{
        color: #fff;
        padding-left: 0px;
    }
    .select--filter .vs-select--input, .select--filter .vs-select--input {
        background-color: transparent !important;
        border-radius: 4px;
        border: transparent !important;
        font-size: 12px;
        color: #FFF;
        padding: 2px 25px;
    }

    .ud--filter {
        font-size: 16px !important;
    }

.prog-plan{
    height: 4px !important;
    background-color: #2D2D2D !important;
}
.prog-plan .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #6D75F6;
    transition: width 0.6s ease;
    border-top-right-radius: 23px;
    border-bottom-right-radius: 23px;
}
.table-credit-history td{
    border-bottom: 0.5px solid #2D2D2D;
}
.td--custom_el > td > div{
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #585859 !important;
    font-family: Avenir Next;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}
@supports (position: sticky){
    .b-table-sticky-header > .table.b-table > thead > tr > th {
        position: sticky;
        top: -2px;
        z-index: 2;
    }
}
.cust__date--picker .el-range-editor .el-range-input::placeholder, .cust__date--picker .el-date-editor .el-input__inner::placeholder {
    color: #FFF !important;
}
.cust__date--picker .el-range-editor .el-range-input, .cust__date--picker .el-date-editor .el-input__inner {
    line-height: 1;
    background-color: #2D2D2D;
    border-color: #2D2D2D;
    color: #FFF !important;
}
.cust__date--picker .el-date-editor .el-range__icon {
    color: #FFF !important;
}
.cust__date--picker .el-date-editor--daterange.el-input__inner{
    width: 235px;
}
.cust__date--picker .el-range-editor.el-input__inner {
    background-color: #2D2D2D !important;
    border-color: #2D2D2D;
}
.el-picker-panel {
    border: none;
}
.el-date-range-picker .el-picker-panel__body {
    background-color: #2d2d2d;
    border-radius: 4px;
}
.el-date-range-picker__content.is-left {
    border-right: none;
}
.el-date-range-picker__content.is-left, .el-date-range-picker__content.is-right {
    color: #fff !important;
    background-color: #2D2D2D;
    border-radius: 4px;
}
.el-date-table > tbody > tr > th {
    font-size: 12px;
    color: #fff !important;
}

.el-date-table td.in-range div, .el-date-table td.in-range div:hover{
    background-color: rgba(109, 117, 246, 0.20) !important;
}
.el-date-table td.start-date div, .el-date-table td.end-date div {
    padding: 0;
}
.el-date-table td.end-date span, .el-date-table td.start-date span {
    background-color: #6D75F6;
}
.el-date-table td span {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.el-picker-panel {
    border: none;
    left: 783px !important;
}
.el-popper[x-placement^=bottom] .popper__arrow {
    top: -6px;
    left: 57.8% !important;
    border-bottom-color: #2D2D2D;
}
.el-popper[x-placement^=bottom] .popper__arrow::after {
    border-bottom-color: #2D2D2D;
}
.el-date-range-picker {
    width: 500px;
}
.el-date-table td.disabled div {
    background-color: transparent !important;
}
.el-picker-panel__icon-btn {
    color: #ffff;
}

.el-picker-panel {
    background-color: #2D2D2D;
    color: #fff;
}

.el-date-picker__header-label {
    color: #fff;
}
</style>

<style scoped>
.ud--container {
    padding: 8px 0px;
    gap: 20px;
}

.ud--apilog__text {
    color: #FFF;
    font-family: Avenir Next;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ud--apilog__here {
    color: #6D75F6 !important;
    font-weight: 600 !important;
    cursor: pointer;
}

.ud--title {
    color: #FFF;
    font-family: Avenir Next;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.ud--subtite {
    color: #FFF;
    font-family: Avenir Next;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.cc--gap {
    gap: 12px;
}

.label--chart {
    visibility: hidden;
    opacity: 0;
    height: 0;

    transition: opacity 0.25s, visibility 0.25s, height 0.25s;
}

.label--chart__active {
    height: auto;
    visibility: visible;
    opacity: 1;
}

.label--chart__text {
    color: #FFF;
    font-family: Avenir Next;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.badge-discount{
    background-color: #2D2D2D;
    padding: 4px 14px;
    border-radius: 12px;
}
.cursor-default{
    cursor: default;
}

.border-none{
    border: none !important;
}
.plans--pr.custom{
    height: 415px !important;
}
.plans--pr{
    height: 395px;
}
.package--title{
    color: #FFF;
    font-family: Avenir Next;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
}
.price--package span{
    font-weight: 100 !important;
}
.price--package{
    color: #FFF;
    font-style: normal;
    font-weight: 700 !important;
    font-family: Avenir Next;
    font-size: 20px;
}
.btn--cancel-plan:hover{
    color: #F00;
    font-size: 12px;
    transition: color 0.3s ease;
}
.btn--cancel-plan{
    color: #ffff;
    font-size: 12px;
    transition: color 0.3s ease;
}
.badge--bill{
    color: #585859;
    font-family: Avenir Next;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    border-radius: 4px;
    background: #1F1F1F;
}
.card--scc__preview-video {
    border-radius: 8px;
    background: #2D2D2D;
    height: 147px;
}

.card--scc {
    border-radius: 12px;
    background: #1F1F1F;
    max-width: 375px;
}

.card--scc__inner {
    gap: 14px;
}

.card--scc__title {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.card--scc__header {
    gap: 6px;
}

.card--scc__description, .card--scc__list--title {
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
}

.card--scc__description {
    font-weight: 400;
}

.card--scc__list--title {
    font-weight: 500;
    text-align: left;
}

.card--scc__list--subtitle {
    color: #999;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.card--scc__list {
    gap: 12px;
}

.card--scc__list li:nth-child(1) {
    list-style-image: url('~@/assets/images/icons/ic_cb.svg');
}

.card--scc__list li:nth-child(2) {
    list-style-image: url('~@/assets/images/icons/ic_cm.svg');
}

.card--scc__list li:nth-child(3) {
    list-style-image: url('~@/assets/images/icons/ic_c&a-protection.svg');
}

.card--scc__list-li::marker {
    text-align: center !important;
    text-align-last: center !important;
}

.card--scc__button {
    border-radius: 6px;
    background-color: #6D75F6;
    width: 100%;
    font-size: 14px;
    color: white;
    font-weight: 600;
    gap: 6px;

    transition: background-color 0.25s;
}

.card--scc__button:disabled {
    background-color: #2D2D2D;
}

.card--scc__button:not(:disabled):hover {
    background-color: #545EED;
}

.scc--rejected {
    color: #DF2723;
}

.onoffswitch {
    position: relative; width: 37px;
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}
.onoffswitch-checkbox {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}
.onoffswitch-label {
    display: block; overflow: hidden; cursor: pointer;
    height: 18px;
    padding: 0;
    line-height: 36px;
    border: 2px solid #E3E3E3;
    border-radius: 36px;
    background-color: #E3E3E3;
    transition: background-color 0.3s ease-in;
}
.onoffswitch-label:before {
    content: "";
    display: block;
     margin: 0px;
    background: #FFFFFF;
    position: absolute;
    top: 1px;
    bottom: 0;
    right: 20px;
    height: 16px;
    width: 16px;
    border: 2px solid #E3E3E3;
    border-radius: 36px;
    transition: all 0.3s ease-in 0s;
    box-shadow: 0px 0px 12.4px rgba(0, 0, 0, 0.5);
}
.onoffswitch-checkbox:checked + .onoffswitch-label {
    background-color: #49E845;
}
.onoffswitch-checkbox:checked + .onoffswitch-label {
   border-color: #49E845;
}
.onoffswitch-checkbox:checked + .onoffswitch-label:before{
    border-color: #ffffff;
}
.onoffswitch-checkbox + .onoffswitch-label:before{
    border-color: #ffffff;
}
.onoffswitch-checkbox:checked + .onoffswitch-label:before {
    right: 1px;
}
.container-card{
    width: 100%;
    height: 58%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.container-card::-webkit-scrollbar {
    width: 6px;
}

        /* Track */
.container-card::-webkit-scrollbar-track{
    background: #2D2D2D;
}

        /* Handle */
.container-card::-webkit-scrollbar-thumb{
    background: #B6B6B6;
    border-radius: 4px;
}

        /* Handle on hover */
.container-card::-webkit-scrollbar-thumb:hover {
    background: #888;
}
.title-btn-purple{
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #6D75F6;
    cursor: pointer;
}
.title-modal-payment{
    font-size: 18px;
    color: #FFFFFF;
}
.ic-eye-1 {
    right: 21px;
    top: 40px;
}
.options-lang{
    background-color: #2D2D2D;
    color: #fff;
    box-shadow: 0 5px 20px 0 rgb(0 0 0 / 5%);
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    visibility: hidden;
    opacity: 0;
}

.item-lang:hover{
    /* background: rgba(109, 117, 246, 0.1) !important; */
    background: #E3DEFF !important;
    border-radius: 5px;
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #6D75F6;
}

.change-lang {
    position: relative;
}

.change-lang .button-lang {
    padding: 0;
}

.options-lang__show {
  transition: all 0.7s;
  right: 0;
  top: 23px;
  opacity: 1;
  visibility: visible;
  box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.75);
}

.arrow-up{
    animation-name: animation-arrow-toup;
    transform: rotate(180deg);
    animation-duration: 0.5s;
}
@keyframes animation-arrow-toup {
  0%   {transform: rotate(0deg);}
  100% {
    transform: rotate(180deg);
  }
}
h3 {
    font-size:22px
}

.title-items-table{
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;

    color: #FFFFFF;
}

.badge-used{
    background: rgba(64, 190, 69, 0.1);
    border-radius: 15px;
    color: #40BE45;
    font-weight: 700;
    font-size: 12px;
    padding: 1px 8px 1px 8px;
}
.badge-noused{
    background: rgba(255, 0, 0, 0.1);
    border-radius: 15px;
    color: #FF0000;
    font-size: 12px;
    padding: 1px 8px 1px 8px;
    font-weight: 700;
}
.badge-paid{
    color: #40BE45;
    background: rgba(64, 190, 69, 0.2);
}
.badge-unpaid{
    background:  rgba(255, 0, 0, 0.2);
    color: #FF0000;
}

.badge{
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    padding-left: 13px;
    padding-right: 17px;
    padding-top: 12px;
    padding-bottom: 9px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 16px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.card {
    border-radius: 10px;
}

.card-visa{
    background-color: #1F1F1F;
    border-radius: 10px;
}

.profile-picture {
    height: 68px;
    width: 68px;
    position: relative;
}
.ic-change-profile {
    position: absolute;
    bottom: -6px;
    left: 25px;
}


.td-table{
    border-bottom: 1px solid red;
}

thead{
    background-color: #1F1F1F !important;
}

.text-grey{
    color : #8C8C8C;
}


.text-purple{
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #6D75F6;
}

.form-control{
    background-color: transparent !important;
    color: white !important;
    border-color: #383838;
}
.form-control-payment{
    color: white !important;
    background: #2D2D2D;
    border-radius: 7.52497px;
}
.form-control-payment-disabled{
    color: #8C8C8C !important;
    background: #2D2D2D;
    border-radius: 7.52497px;
    background-color: #2D2D2D !important;
    border-color: #2D2D2D !important;
}
.form-control-select{
    background-color: transparent !important;
    color: #6D75F6 !important;
    border-color: black;
    padding: 7px;
    margin-bottom: 0px;
}

.border-plan-billing{
    border: 1.18164px solid #2D2D2D;
    border-radius: 10px;
}

.con-select {
    width: 125px;
    clear: both;
    color: #ffffff;
    background-color: transparent !important;
}

.vs-select--input{
    color: #6D75F6;
    font-weight: 500;
    background: black !important;
}
.read-only{
    background-color: #2D2D2D !important;
    border-color: #2D2D2D !important;
}

.form-control::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.pill-point{
    background: rgb(169,68,174);
    background: linear-gradient(135deg, rgba(169,68,174,1) 0%, rgba(192,127,224,1) 56%, rgba(62,62,250,1) 100%);
    height: 40px;
    /* width: 100px; */
    border-radius: 25px;
}

.user-profile hr {
    border:none;
    border-left: 1px solid #2D2D2D;
    color: #2D2D2D;
    /* width:1px; */
    height: 4rem;
}

.list-option .inner-option hr {
    border:none;
    background: #383838;
}

.title-p{
    font-size: 16px;
}
.button-p{
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #6D75F6;
    margin-bottom: 0px;
    background: transparent;
    border: none;
}


.text-pharagraph {
    font-size: 12px;
    text-align: left;
}

.btn-purple {
    background-color: #6D75F6;
    border: 1px solid #6D75F6;
    box-shadow: none;
}

.btn-disabled {
    background-color: #2D2D2D;
    border: 1px solid #2D2D2D;
    color: white;
    box-shadow: none !important;
}

.invert{
    filter: invert(100%);
}
.progress .bg-primary {
  background-color: red;
}

.text-alert{
    font-size: 12px !important;
    color: #EB5757;
}

.quota-info__title {
    font-size: 16px;
    font-weight: 900;
    color: white;
}
.quota-info__subtitle {
    font-size: 12px;
    font-weight: 100;
    color: white;
    margin: 0;
}

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ce__container {
    height: calc(100vh - 250px);
    gap: 15px;
}

.ce__small-text {
    font-size: 18px;
    color: #585859;
    font-weight: 400;
}

.ce__button-cc, .ce__button-cc-sm {
    background-color: #6D75F6;
    color: white;
    font-weight: 900;
}

.ce__button-cc {
    padding: 10px 30px;
    width: 213px;
    font-size: 16px;
}

.ce__button-cc-sm, .ce__button-jc {
    padding: 8px 15px;
    font-size: 14px;
}

.ce__button-cc:hover,.ce__button-cc-sm:hover {
    background-color: #545EED;
}

.ce__button-jc {
    color: #6D75F6 ;
    font-weight: 900;
    border: 1.5px solid #6D75F6;
}

.cca {
    gap: 10px;
}

.c__title {
    font-size: 1.125rem;
    font-weight: 900;
    color: white;
}

.c__list-community {
    display: flex;
    gap:15px;
    overflow-x: auto;
    /* grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */
}

.c__list-community::-webkit-scrollbar {
  display: none;
}

.c__card {
    border-radius: 10px;
    background-color: #2D2D2D;
    padding: 15px;
    text-align: center;
    min-width: 210px;
    max-width: 210px;
}

.c__card-img {
    width: 51px;
    height: 51px;
    border-radius: 50%;
}

.c__card-title {
    font-size: 12px;
    font-weight: 900;
    color: #fff;
    max-width: 100%;
}

.c__card-subtitle {
    font-size: 10px;
    font-weight: 400;
    color: #6D75F6;
}

.c__card-subtitle-danger {
    color: #DF2723 !important;
}

.clp-container {
    gap: 15px;
}

.c__clp-img {
    height: 45px;
    width: 45px;
}

.c__clp-title {
    font-size: 14px;
    font-weight: 900;
    color: #fff;
}

.c__clp-subtitle {
    font-size: 10px;
    font-weight: 400;
    color: #8C8C8C;
}

.button-active, .button-activated {
    font-size: 10px;
    font-weight: 900;
    border-radius: 4px;
    padding: 5px;
    width: 100%;
}

.button-active:disabled {
    background: rgba(109, 117, 246, 0.2);
    color: #6D75F6;
}

.button-active {
    color: #fff;
    background-color: #6D75F6;
    transition: background-color 0.25s;
}

.button-active:not(:disabled):hover {
    background-color: #545EED;
}

.oc--empty {
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.oce--text {
    font-size: 20px;
    font-weight: 400;
    color: #585859;
    margin: 0;
}

.ud--cu__action {
    gap: 4px;
}

.ud--cu__button {
    color: #8C8C8C;
    font-family: Avenir Next;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 5px;
    height: 40px;

    transition: color 0.25s, background-color 0.25s
}

.ud--cu__button-active, .ud--cu__button:hover {
    color: #fff;
    background-color: #1F1F1F;
}

.udr--date {
    gap: 10px;
}
</style>