<template>
    <div>
        <b-modal id="sendfeedback--cancel-subscription" class="px-0" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" modal-class="cr__modal" content-class="cr__content" dialog-class="cr__dialog d-flex justify-content-center" size="sm" no-close-on-backdrop>
			<div class="container-fluid">
				<div class="d-flex justify-content-end mb-2">
					<button
						class="btn button--cr__close d-flex align-items-center justify-content-center"
						@click="$bvModal.hide('sendfeedback--cancel-subscription')"
					>
						<font-awesome-icon icon="fa-solid fa-xmark"/>
					</button>
				</div>
				<div class="cr--main d-flex flex-column">
					<h4 class="cr--title">{{ $t('goodbye-feedback') }}</h4>
                    <h6 class="current__text-title mb-5">{{ $t('goodbye-feedback-p1') }} <br>
                        {{ $t('goodbye-feedback-p2') }}</h6>
					<div class="cr--list-role d-flex flex-column mb-4">
                        <div v-for="(items, index) in list_feedback" :key="index" class="pr--package-card text-center cursor-pointer" :class="{'active': items.active}" @click="selectFeedback(index, items.text)">
                            <h5 class="title--badge">{{ items.text }}</h5>
                        </div>
                        <div class="feedback__content-tw position-relative" :class="{'show': feedback == 'Others' || feedback == 'Lainnya'}">
                            <textarea v-model="feedbackText" maxlength="200" class="feedback__content--textarea text-white"></textarea>
                            <div class="feedback__text--counter">
                                <p class="mb-0 p-2">{{ feedbackText?.length }}/200</p>
                            </div>
                        </div>
                        <div class="row text-center d-flex justify-content-center">
                            <p class="par--1 mb-0">
                                {{ $t('feedback-plans-footer') }}, <br> {{ $t('please') }} <a href="https://wa.me/message/2FGAFWFOX6MQE1" target="_blank" class="link--sovia">{{ $t('contact') }}</a> {{ $t('our-support-team') }}, Sovia.
                            </p>
                        </div>
					</div>
				</div>
			</div>
            <div class="modal--footer d-flex align-items-center justify-content-center pt-3 pb-2">
                <button class="btn btn--purple px-4" @click="sendFeedback()" :disabled="!this.feedback || this.feedback == 'Others' || this.feedback == 'Lainnya' && !this.feedbackText && this.feedbackText == ''">{{ $t('submit') }}</button>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    props: {
        planProgress: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
	data() {
		return {
			list_feedback: [{text: this.$t('ask-feedback-1'), active: false}, {text: this.$t('ask-feedback-2'), active: false}, {text: this.$t('ask-feedback-3'), active: false}, {text: this.$t('ask-feedback-4'), active: false}, {text: this.$t('ask-feedback-other'), active: false}],
            feedbackText: '',
            feedback: ''
		}
	},

	methods: {
        sendFeedback(){
            if(this.feedback == 'Others'){
                this.$emit('sendFeedback', this.feedbackText);
            }else{
                this.$emit('sendFeedback', this.feedback);
            }
        },
		handleChooseRole() {
			this.$router.push({ name : 'Soca-Certified-Creator' })
		},
        selectFeedback(idx, choice){
            this.list_feedback.forEach((v) => v.active = false );
            this.list_feedback[idx].active = true;
            this.feedback = choice;
        },
        convertCurrency(amount){
            if (amount >= 1000) {
                return (amount / 1000).toFixed() + "K";
            }
            return amount.toString();
        },
        getDateAndYearOnly(data){
            const date = new Date(data);
            date.setDate(date.getDate() - 1);
            
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

            const day = date.getDate();
            const month = monthNames[date.getMonth()];
            const year = date.getFullYear();


            return `${month} ${day}, ${year}`;
        },
	}
}
</script>

<style scoped>
.feedback__content--textarea {
	background: transparent;
	border: none;
	padding: 12px 12px 0px 13px;
	resize: none;
	height: 80%;
	width: 100%;
}

.feedback__content-tw.show {
    height: 120px;
    opacity: 1;
    transition: all 0.5s;
}
.feedback__content-tw {
	height: 0px;
	position: relative;
    opacity: 0;
    transition: all 0.5s;
    border-radius: 10px;
	border: 1px solid #585859;
}

.feedback__text--counter {
	color: #585859;
	font-family: Avenir Next;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
    text-align: right;
    margin-top: -9px;
}
.link--sovia{
    color: #6D75F6;
    font-family: Avenir Next;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
}
.pr--body{
    padding: 0px 2rem;

}
.pr--package-card.active{
    border: 1px solid #6D75F6;
    transition: border .25s;
}
.pr--package-card:hover{
    border: 1px solid #6D75F6;
    transition: border .25s;
}
.pr--package-card{
    padding: 1rem 3rem;
    border-radius: 6px;
    background: #2D2D2D;
    border: 1px solid #2D2D2D;
    transition: border 0.3s ease;
}
.btn--gray{
    border-radius: 4px;
    background: #2D2D2D;
    color: #FFF;
}
.btn--purple{
    border-radius: 4px;
    background: #6D75F6;
    color: #FFF;
}
.modal--footer{
    border-top: 0.5px solid #2D2D2D;
}
.subs--date{
    color: #FFF;
    font-family: 'Avenir Next';
    font-size: 14px;
    font-style: normal;
    font-weight: 7s00;
}
.title--badge{
    color: #FFF;
    font-family: Avenir Next;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}
.par--1{
    color: #FFF;
    text-align: center;
    font-family: Avenir Next;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}

.package--title{
    color: #FFF;
    font-family: Avenir Next;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
}
.price--package span{
    font-weight: 100 !important;
}
.price--package{
    color: #FFF;
    font-style: normal;
    font-weight: 700 !important;
    font-family: Avenir Next;
    font-size: 20px;
}

.current__text-title{
    color: #FFF;
    text-align: center;
    font-family: Avenir Next;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
}
.button--cr__close {
	width: 27px;
	height: 27px;
	border-radius: 50%;
	background-color: #fff;
	color: black;
}

.cr--main {
	gap: 8px;
}

.cr--list-role {
    padding: 0px 3.5rem;
	gap: 24px;
}

.cr--title {
	color: #FFF;
	font-size: 22px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
    text-align: center;
}

.cr--role__card {
	border-radius: 8px;
	background-image: linear-gradient(to bottom, #FFFFFF33, #ffffff00);
	/* background-image: 1px solid rgba(255, 255, 255, 0.20); */
	padding: 2px;
}

.cr--role__card-inner {
	background: linear-gradient(0deg, #121418 0%, #121418 100%), rgba(49, 48, 54, 0.30);
	border-radius: 8px;
	backdrop-filter: blur(2px);
}

.cr--role__main {
	gap: 4px;
}

.cr--role__title {
	color: #FFF;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.cr--role__description {
	color: #FFF;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
</style>

<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#sendfeedback--cancel-subscription .modal-content{
    border-radius: 12px !important;
    background-color: #1f1f1f;
    font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
	color: #fff;
}

.cr__modal + .modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}

.cr__dialog {
	max-width: none;
}

.cr__content {
	width: 539px;
}
</style>
