<template>
    <div>
        <b-modal id="dgc-modal" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="md" @hidden="resetModal">
            <div v-if="listMember" class="container-fluid mt-2 text-center text-white mb-3">
                <img :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + listMember.community_name" :alt="listMember.community_name" class="dgc__cimg">
				<p class="dgc__cn text-truncate mb-0 mt-2">{{ listMember.community_name }}</p>
                <h5 class="font-weight-bold mb-2 mt-3" style="font-size:18px;">Select a max. of {{ listMember.max_member }} members <br> that you will include in this community.</h5>
				<p class="dgclmc mt-3 mb-0 text-left">Members</p>
				<div class="dgcl__container">
					<ul>
						<li v-for="(data, index) in listMember.list_member" :key="index" class="mb-4 mt-3">
							<vs-checkbox color="success" :disabled="!checkedUser(data._id) && list_checked.length == listMember.max_member" v-model="list_checked" :vs-value="data._id">
								<div class="dgcl__ui d-flex align-items-center ml-1">
									<img v-if="data.community_picture" :src="data.community_picture" :alt="data.name" class="dgcl__img" :class="{ 'dgcl__img-filter' : !checkedUser(data._id) }">
									<img v-else :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + data.name" :alt="data.name" class="dgcl__img" :class="{ 'dgcl__img-filter' : !checkedUser(data._id) }">
									<p class="ui__name text-truncate m-0" :title="data.name" :class="{ 'ui__name-filter' : !checkedUser(data._id) }">{{ truncatedText(data.name) }}</p>
								</div>
							</vs-checkbox>
						</li>
					</ul>
				</div>
                <div class="dgc_actions d-flex justify-content-center mt-3">
                    <button class="btn dgc_button-cancel" @click="$bvModal.hide('dgc-modal'), resetModal()"><p class="mx-2 mb-0 font-weight-bold text-white">Cancel</p></button>
                    <button class="btn dgc_button-oke" :disabled="list_checked.length == 0" @click="save()"><p class="mx-2 mb-0 font-weight-bold text-white">Save</p></button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { selectMemberDowngradeCommunity } from '@/services/community/community.service'
import { AlertUtils } from "@/mixins/AlertUtils";

export default {
	mixins: [AlertUtils],
	data() {
		return {
			list_dummy : ['Tegar', 'Isman Karbon sipatugelang', 'Ucok Simbolon Paputungan martabak manis', 'Gonjol Dempak sirah', 'Fahry Siksa', 'Seringai Beringas', 'Ucok Simbolon Paputungan martabak manis Simbolon Paputungan martabak manis Simbolon Paputungan martabak manis'],
			list_checked : []
		}
	},

	props : {
		communityId : {
			type: String,
			default: null
		},
		listMember : {
			type: Object,
			default() {
				return null
			}
		}
	},

	methods: {
		truncatedText(text) {
			if(text) {
				let length = 30

				if (text.length > length) {
					return text.substring(0, length) + "...";
				} else {
					return text;
				}
			}
		},

		checkedUser(val) {
			return this.list_checked.includes(val);
		},

		resetModal() {
			this.list_checked = []
		},

		save() {
			let formData = {
				community_id : this.communityId,
				list_selected_member : this.list_checked
			}

			selectMemberDowngradeCommunity(formData)
			.then((response) => {
				if(response.status) {
					this.alertSuccess('Changes saved successfully')
					this.$emit('update-community')
					this.$bvModal.hide('dgc-modal')
				}
			})
		}
	}
}
</script>

<style scoped>
.dgc__cimg {
	width: 79px;
	height: 79px;
	border-radius: 50%;
}

.dgc_actions {
	gap: 10px;
}

.dgc_button-cancel, .dgc_button-oke {
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.25s;
  color: #fff;
}

.dgc_button-cancel {
  background-color: #2d2d2d;
}

.dgc_button-cancel:hover {
  background-color: #2C2C2C;
}

.dgc_button-oke {
  background-color: #6D75F6;
}

.dgc_button-oke:hover {
  background-color: #545EED;
}

.dgc__cn {
	font-size: 14px;
	width: 100%;
	font-weight: 900;
}

.dgclmc {
	font-size: 12px;
	color: #8C8C8C;
}

.dgcl__container {
	max-height: 250px;
	overflow-y: auto;
	overflow-x: hidden;
}

.dgcl__img {
	width: 25px;
	height: 25px;
	border-radius: 50%;
}

.dgcl__ui {
	gap: 10px;
}

.ui__name {
	font-size: 12px;
	font-weight: 900;
	width: 100%;
}

.ui__name-filter, .dgcl__img-filter {
	filter : brightness(0.7)
}

</style>

<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#dgc-modal .modal-content{
    background-color: #222222;
	border-radius: 15px !important;
    font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}

@media only screen and (min-width: 860px) {
	#dgc-modal .modal-dialog {
		max-width: 430px;
		margin: 1.75rem auto;
	}
}

.dgcl__container ul li .con-vs-checkbox {
	justify-content: start;
	align-items: center;
}
</style>