<template>
    <div>
        <b-modal id="detail-community-modal" dialog-class="dcm__dialog" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="md" @hidden="resetModal">
			<div v-if="dataCommunity" class="dcm__container">
				<div class="d-flex justify-content-between">
					<button class="btn button-back" @click="addMember ? addMember = false : edit ? edit = edit = false : $bvModal.hide('detail-community-modal')">
						<font-awesome-icon icon="fa-solid fa-chevron-left"/>
						<p class="btn__title m-0">{{ addMember ? 'Add' : 'Detail' }} Members</p>
					</button>
					<button v-if="!addMember && (data_community_init && data_community_init.owner_community_status)" class="btn button-topup-dcm" @click="$router.push({ name : 'TopUp' })">
                        <font-awesome-icon icon="fa-solid fa-plus" class="mr-1"/>
						<span>Top up</span>
					</button>
				</div>
				<template v-if="!addMember">
					<div class="dcm__pc my-4">
						<div class="container-fluid">
							<div class="row">
								<div class="col-6 d-flex align-items-center vertical-line">
									<img v-if="data_community_init && data_community_init.community_picture" :src="data_community_init.community_picture" :alt="data_community_init.community_name" class="dcm__profile-img">
                                    <img v-else :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + data_community_init.community_name" :alt="data_community_init.community_name" class="dcm__profile-img">
									<div class="ml-3 d-flex flex-column">
										<span class="dcm_card__title mr-2" :title="data_community_init.community_name">{{ truncatedText(data_community_init ? data_community_init.community_name : '', 'community') }}</span>
										<span class="dcm_card__subtitle">You are {{ data_community_init && data_community_init.owner_community_status ? 'owner' : 'member' }}</span>
									</div>
								</div>
								<div class="col-6">
									<div class="dcm__package d-flex ml-3">
										<!-- <div class="dcmp d-flex flex-column justify-content-center align-items-center">
											<img src="@/assets/images/icons/ic_package_swc.png" alt="SWC" title="SWC" class="dcmp-img">
											<p class="dcmp-title m-0">{{ data_community_init && data_community_init.community_allocations[0]?.swc_quota ? data_community_init.community_allocations[0]?.swc_quota : '0' }}</p>
										</div> -->
										<div class="dcmp d-flex flex-column justify-content-center align-items-center">
											<img src="@/assets/images/icons/ic_package_question.png" alt="Question" title="Question" class="dcmp-img">
											<p class="dcmp-title m-0">{{ data_community_init && data_community_init.community_allocations[0]?.question_generates ? data_community_init.community_allocations[0]?.question_generates : '0' }}</p>
											<span class="dcmp-desc m-0">questions</span>
										</div>
										<div class="dcmp d-flex flex-column justify-content-center align-items-center">
											<img src="@/assets/images/icons/ic_package_audio2.png" alt="Audio" title="Audio" class="dcmp-img">
											<div class="d-flex align-items-center">
												<p class="dcmp-title m-0">{{ data_community_init && data_community_init.community_allocations[0]?.audio_duration ? data_community_init.community_allocations[0]?.audio_duration : '0' }}</p>
												<span class="cch--text">{{ calculateHour(data_community_init && data_community_init.community_allocations[0]?.audio_duration ? data_community_init.community_allocations[0]?.audio_duration : 0) }}</span>
											</div>
											<span class="dcmp-desc m-0">minutes</span>
										</div>
										<div class="dcmp d-flex flex-column justify-content-center align-items-center">
											<img src="@/assets/images/icons/ic_package_img.png" alt="Image" title="Image" class="dcmp-img">
											<div class="d-flex align-items-center">
												<p class="dcmp-title m-0">{{ data_community_init && data_community_init.community_allocations[0]?.image_generates ? data_community_init.community_allocations[0]?.image_generates : '0' }}</p>
											</div>
											<span class="dcmp-desc m-0">images</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="dcm__list-member mt-3" :class="{ 'h-300' : data_community_init.data_member.length == 0 }">
						<table v-if="data_community_init.data_member.length > 0">
							<tr>
								<th style="width: 40%;" class="font-weight-normal">Members (<span style="color:#fff">{{ data_community_init && data_community_init.joined_member ? data_community_init.joined_member : 0 }}</span>/{{ data_community_init && data_community_init.community_allocations[0]?.member_allocation ? data_community_init.community_allocations[0]?.member_allocation : 1 }})</th>
								<th style="width: 60%;" class="position-relative font-weight-normal">Limitations <img src="@/assets/images/icons/ic_info_2.svg" alt="Limitations" class="li__img" @mouseover="addHoverClass" @mouseout="removeHoverClass">
									<div class="li__tooltip" :class="{ 'd-block': tooltip_hovered, 'd-none' : !tooltip_hovered}">
										<p class="m-0">If you are the owner the of workspace you can adjust the allocations for each members</p>
									</div>
								</th>
							</tr>
							<tr v-for="(data, index) in data_community_init.data_member" :key="index">
								<td>
									<div class="dclm d-flex align-items-center py-2">
										<!-- <transition name="slide-fade"> -->
											<button v-if="edit && !data.is_owner" class="btn bkm" @click="deleteUser(index)">
												<font-awesome-icon icon="fa-solid fa-circle-minus" class="mr-1"/>
											</button>
										<!-- </transition> -->
										<img v-if="data.picture" :src="data.picture" :alt="data.name ? data.name : data.email" class="dclm-img" :class="{ 'dclm__pending' : !data.join_status }">
										<img v-else :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + (data.name ? data.name : data.email)" :alt="data.name ? data.name : data.email" class="dclm-img" :class="{ 'dclm__pending' : !data.join_status }">
										<div class="d-flex flex-column mx-1">
											<span class="dclm__name text-truncate" :class="{ 'dclm__pending' : !data.join_status }" :title="data.name ? data.name : data.email">{{ truncatedText(data.name ? data.name : data.email, 'name') }} <span v-if="data.is_you" class="font-weight-normal">(you)</span></span>
											<span class="dclm__status m-0" :class="{ 'dclm__pending' : !data.join_status }">{{ !data.join_status ? 'Waiting to be confirmed' : data.is_owner ? 'Owner' : 'Member' }}</span>
										</div>
									</div>
								</td>
								<td>
									<div v-if="data.join_status" class="dclm__form d-flex pt-3">
										<div class="text-center w-100">
											<input :disabled="!edit" type="number" v-model="data.data_limit[0].question_generates_limit" class="form-control">
											<span>Qs</span>
										</div>
										<div class="text-center w-100">
											<input :disabled="!edit" type="number" v-model="data.data_limit[0].audio_duration_limit" class="form-control">
											<span>mins</span>
										</div>
										<div class="text-center w-100">
											<input :disabled="!edit" type="number" v-model="data.data_limit[0].image_generates_limit" class="form-control">
											<span>images</span>
										</div>
									</div>
								</td>
							</tr>
						</table>
						<div v-else class="h-100 d-flex align-items-center justify-content-center">
							<p class="m-0">No members</p>
						</div>
					</div>
					<div v-if="data_community_init && data_community_init.owner_community_status" class="dcm__actions d-flex justify-content-between mt-4">
						<button class="btn bam" @click="data_community_init.community_allocations[0]?.package_id ? addMember = !addMember : $bvModal.show('pir-modal')">
							<font-awesome-icon icon="fa-solid fa-user-plus" class="mr-1"/>
							Add Member
						</button>
						<button class="btn esc" @click="data_community_init.community_allocations[0]?.package_id ? saveChange() : $bvModal.show('pir-modal')">{{ edit ? 'Save Changes' : 'Edit' }}</button>
					</div>
				</template>
				<template v-else>
					<div class="am mt-4 d-flex flex-column">
						<label for="inputMember">Invite Using Email</label>
						<div class="am__input-wrapper" :class="{ 'pt-0' : emailList.length > 0 }">
							<div class="container-fluid">
								<div class="row">
									<div v-for="(data, index) in emailList" :key="index" class="col-auto">
										<div class="am__list mr-1 mt-2" :class="{ 'am__list-marked' : is_duplicated_email_index == index }">
											<img :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + data" :alt="data">
											<span class="am__name">{{ data }}</span>
											<span class="am__icon cursor-pointer" :class="{ 'am__icon-red' : is_duplicated_email_index == index }" @click="deleteEmail(index)">
												<font-awesome-icon icon="fa-solid fa-xmark"/>
											</span>
										</div>
									</div>
								</div>
							</div>
							<input type="email" id="inputMember" v-model="email" class="form-control am__input" :class="{ 'mt-2' : emailList.length > 0 }" placeholder="Input email (press Enter)" v-on:keyup.enter="addEmail">
						</div>
						<span class="am__input-note mt-1">*min. 1 members</span>
						<label for="inputMessage" class="mt-3">Message (optional)</label>
						<textarea name="message" id="inputMessage" cols="20" rows="10" placeholder="Input your message" class="form-control"></textarea>
						<div class="am__actions d-flex mt-3 justify-content-center">
							<button class="btn btnam-cancel" @click="addMember = !addMember, emailList = [], is_duplicated_email_index = null">Cancel</button>
							<button class="btn btnam-submit" :disabled="emailList.length == 0 || load_addmember || is_duplicated_email_index != null" @click="addMemberAction">{{ load_addmember ? 'Loading...' : 'Submit' }}</button>
						</div>
					</div>
				</template>
			</div>
        </b-modal>
		<InvitationNotSent :list-email="duplicateUser" @close-insm="handleCloseInsm"/>
		<AlertKickMember :member="member_kicked_name" @kick-member="handleKickMember" @cancel-kick-member="handleCancelKickMember"/>
		<PackageRequired />
		<FormatEmailIncorrect />
    </div>
</template>

<script>
import { addMemberCommunity, kickMemberCommunity, setLimitCommunity } from '@/services/community/community.service'
import { AlertUtils } from "@/mixins/AlertUtils";
import InvitationNotSent from "../../community/modal/InvitationNotSent.vue"
import AlertKickMember from "../../community/modal/AlertKickMember.vue"
import PackageRequired from "../../community/modal/PackageRequired.vue"
import FormatEmailIncorrect from "../../community/modal/FormatEmailIncorrect.vue"

export default {
	mixins: [AlertUtils],
	components: {
		InvitationNotSent,
		AlertKickMember,
		PackageRequired,
		FormatEmailIncorrect
	},
	data() {
		return {
			edit: false,
			addMember: false,
			load_addmember: false,
			tooltip_hovered: false,
			is_duplicated_email_index: null,
			email: null,
			member_kicked_name: null,
			deleted_user_index: null,
			data_community_init: this.dataCommunity,
			emailList : [],
			deletedUser: [],
			duplicateUser: [],
		}
	},

	props : {
		communityId : {
			type: String,
			default: null
		},
		dataCommunity : {
			type: Object,
			default() {
				return null
			}
		}
	},

	watch: {
		edit(val) {
			if(val == false) {
				this.deletedUser = []
				this.$emit('update-change', this.communityId)
				// if(this.deletedUser.length > 0) {
				// 	this.deletedUser.forEach((e) => {
				// 		this.data_community_init.data_member.push(e)
				// 	})
				// 	this.deletedUser = []
				// }
				// this.data_community_init = this.dataCommunity
			}
		},

		addMember(newVal) {
			if(newVal) {
				this.edit = false
			}
		},

		dataCommunity(newVal) {
			this.data_community_init = newVal
		},

		emailList: {
			handler() {
				this.markDuplicates();
			},
			deep: true
		}
	},

	methods: {
		truncatedText(text , type) {
			if(text) {
				let length = type == 'name' ? 15 : 20

				if (text.length > length) {
					return text.substring(0, length) + "...";
				} else {
					return text;
				}
			}
		},

		deleteUser(index) {
			this.deleted_user_index = index
			this.member_kicked_name = this.data_community_init.data_member[index].name ? this.data_community_init.data_member[index].name : this.data_community_init.data_member[index].email
			this.$bvModal.show('akm-modal')
		},

		addEmail(e) {
			if (e.target.value != null && e.target.value != '') {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Ekspresi reguler untuk memvalidasi alamat email
				if (emailRegex.test(e.target.value)) {
					this.emailList.push(e.target.value);
				} else {
					this.$bvModal.show('fei-modal')
				}
            }
            this.email = null
		},

		deleteEmail(index) {
			this.emailList.splice(index, 1)
		},

		addMemberAction() {
			this.load_addmember = true
			let formData = {
				community_id : this.communityId,
				list_member : this.emailList
			}

			addMemberCommunity(formData)
			.then((response) => {
				if(response.status) {
					this.$emit('update-change', this.communityId)
					this.load_addmember = false
					this.addMember = false
					this.emailList = []
					if(response.data.duplicate_member.length > 0){
						this.duplicateUser = response.data.duplicate_member
						this.$bvModal.show('ins-modal')
					} else {
						this.alertSuccess('Add member successfully')
					}
				} else {
					this.load_addmember = false
					this.emailList = []
					this.$bvModal.show('pir-modal')
				}
			})
		},

		handleCloseInsm(){
			this.duplicateUser = []
			this.$bvModal.hide('ins-modal')
		},

		resetModal() {
			this.addMember = false
			this.edit = false
			this.is_duplicated_email_index = null
			this.deletedUser = []
			this.$emit('update-community')
		},

		async saveChange() {
			if(this.edit) {
				if(this.deletedUser.length > 0) {
					await this.kickMember()
				}
				await this.limitasi()
			}
			this.edit = !this.edit
		},

		async kickMember() {
			let email_list = []

			this.deletedUser.forEach((e) => {
				email_list.push(e.email)
			})

			let formData = {
				community_id : this.communityId,
				list_kick_member : email_list
			}

			await kickMemberCommunity(formData)
			.then((response) => {
				if(response.status) {
					this.deletedUser = []
				}
			})
		},

		async limitasi() {
			let formData = {
				list_set_limit : []
			}

			this.data_community_init.data_member.forEach((e) => {
				formData.list_set_limit.push(e.data_limit[0])

			})

			console.log(formData)
			await setLimitCommunity(this.communityId, formData)
			.then((response) => {
				if(response.status) {
					this.alertSuccess('Changes saved successfully')
				}
			})
		},

		addHoverClass() {
			this.tooltip_hovered = true;
		},

		removeHoverClass() {
			this.tooltip_hovered = false;
		},

		handleKickMember() {
			const deletedData = this.data_community_init.data_member.splice(this.deleted_user_index, 1)[0];
			this.deletedUser.push(deletedData)
			this.$bvModal.hide('akm-modal')
		},

		handleCancelKickMember() {
			this.deleted_user_index = null
			this.$bvModal.hide('akm-modal')
		},

		markDuplicates() {
			const duplicates = {};

			for (let i = 0; i < this.emailList.length; i++) {
				const email = this.emailList[i];
				if (!duplicates[email]) {
					duplicates[email] = [];
				}
				duplicates[email].push(i);
			}

			let lastDuplicateIndex = null;
			for (const email in duplicates) {
				if (duplicates[email].length > 1) {
					lastDuplicateIndex = duplicates[email][duplicates[email].length - 1];
				}
			}

			this.is_duplicated_email_index = lastDuplicateIndex !== null ? lastDuplicateIndex : null;
		},

		calculateHour(menit) {
			if (menit >= 60) {
				const jam = Math.floor(menit / 60);
				console.log(jam)
				return `(${jam}h)`;
			} else {
				return null;
			}
		}
	}
}
</script>

<style scoped>
.button-back {
	color: #fff;
	box-shadow: none;
	display: flex;
	align-items: center;
	gap: 10px;
	padding-left:0;
}

.button-topup-dcm, .esc {
	color: #fff;
	box-shadow: none;
	font-size: 12px;
	background-color: #6D75F6;
	padding: 0px 20px;
	transition: background-color 0.25s;
}

.button-topup-dcm, .esc:hover {
	background-color: #545EED;
}

.bam {
	box-shadow: none;
	color: #6D75F6;
	background-color: transparent;
	font-weight: bold;
	padding-left: 0;
}

.esc {
	font-weight: bold;
}

.bkm {
	color: red;
	background-color: transparent;
	box-shadow: none;
	padding: 0;
}

.btn__title {
	font-size: 14px;
}

.dcm__container {
	padding: 10px;
}

.dcm__title {
	font-size: 20px;
	font-weight: 900;
}
.dcm__list-member {
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 300px;
	text-align: left;
}

.h-300 {
	height: 300px !important;
}

.dcm__card-community {
	background-color: #2D2D2D;
	border-radius: 10px;
	width: 100%;
	padding: 15px 25px;
	cursor: pointer;
	transition: background-color 0.25s;
}

.dcm__card-community:hover {
	background-color: #313030;
}

.dcm__profile-img {
	width: 37px;
	height: 37px;
	border-radius: 50%;
}

.dcm_card__title {
	color: white;
	font-size: 14px;
	font-weight: 900;
	margin: 0;
}

.dcm_card__subtitle {
	font-size: 10px;
	color: #8C8C8C;
}

.dcm__ic-chevron-right {
	font-size: 10px;
    margin-left: auto;
	margin-bottom: 14px;
	color: #8C8C8C;
}

.vertical-line {
    border-right: 0.5px solid #8C8C8C;
    /* height: 35px;  */
}

.dcm__package {
	gap: 20px;
}

.dcmp-img {
    height: 35px;
    width: 35px;
}

.dcmp-title {
    font-size: 16px;
    font-weight: 900;
	line-height: 19px;
    color: #fff;
}

.dcmp-desc, .dclm__status {
	font-size: 10px;
	line-height: 14px;
	color: #8C8C8C;
}

.dcm__list-member table{
	width: 100%;
}

.dcm__list-member table tr th{
	padding: 0;
	color: #8C8C8C;
}

.dclm__form span{
	color: #8C8C8C;
	font-size: 10px;
}

.dclm {
	gap: 5px;
}

.dclm__form {
	gap: 7px;
}

.dclm__form input {
	border-color: #585859;
	background-color: transparent;
	box-shadow: none;
	color: white;
	transition: border 0.25s;
}

.dclm__form input:disabled {
	background-color: #2D2D2D;
	border-color: #2D2D2D;
}

.dclm-img {
	width: 25px;
	height: 25px;
	border-radius: 50%;
}

.dclm__name {
	font-size: 12px;
	color: #fff;
	font-weight: 900;
	line-height: 16px;
}

.dclm__pending {
	filter : brightness(0.7)
}

.am label {
	font-size: 14px;
	color: #fff;
}

.am__input-wrapper {
	border: 2px solid #2D2D2D;
	border-radius: 4px;
	padding: 5px;
}

.am__input {
	background-color: transparent;
	border: none;
	color: white;
	box-shadow: none;
	font-size: 14px;
}

.am__input::placeholder {
	font-size: 14px;
}

.am__list {
	background-color: #2D2D2D;
	color: #fff;
	width: auto;
    border: 1;
    border-radius: 15px;
    -moz-appearance:none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    height: 30px;
	display: flex;
	align-items: center;
	padding: 10px;
	gap: 5px;
}

.am__list img {
	width: 20px;
	height: 20px;
	border-radius: 50%;
}

.am__list-marked {
	border: 1px solid red
}

.am__name {
	font-size: 14px;
	color: #fff;
}

.am__icon {
	font-size: 10px;
}

.am__icon-red {
	color: red;
}

.am__input-note {
	font-size: 14px;
	color: #fff;
}

.am textarea {
	padding: 10px;
	border: 2px solid #2D2D2D;
	background-color: transparent;
	box-shadow: none;
	height: 100px;
	resize: none;
	color: #fff;
}

.am__actions {
	gap: 10px;
}

.btnam-cancel, .btnam-submit {
  border-radius: 6px;
  font-size: 15px;
  font-weight: 600;
  transition: background-color 0.25s;
  color: white;
}

.btnam-cancel {
  background-color: #2D2D2D;
}

.btnam-cancel:hover {
  background-color: #2C2C2C;
}

.btnam-submit {
  background-color: #6D75F6;
}

.btnam-submit:hover {
  background-color: #545EED;
}

.btnam-submit[disabled] {
  background-color: #1F1F1F;
  color: #585859;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.li__tooltip {
	position: absolute;
	background: #2D2D2D;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
	border-radius: 2px;
	max-width: 180px;
	color: white;
	font-size: 11px;
	font-weight: 400;
	padding: 10px;
	right: 15px;
    top: 22px;
}

.cch--text {
	font-size: 12px;
	line-height: 16px;
	color: #FFFFFF;
	font-weight: 900;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#detail-community-modal .modal-content{
    border-radius: 15.05px !important;
    background-color: #1F1F1F;
    font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
	color: #fff;
}

.dcm__dialog {
	/* max-width: none;
	width: 600px; */
}
</style>
