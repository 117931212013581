<template>
    <div>
        <b-modal id="choose-role-modal" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" modal-class="cr__modal" content-class="cr__content" dialog-class="cr__dialog d-flex justify-content-center" size="lg">
			<div class="container-fluid">
				<div class="d-flex justify-content-end mb-2">
					<button
						class="btn button--cr__close d-flex align-items-center justify-content-center"
						@click="$bvModal.hide('choose-role-modal')"
					>
						<font-awesome-icon icon="fa-solid fa-xmark"/>
					</button>
				</div>
				<div class="cr--main d-flex flex-column px-5">
					<h4 class="cr--title">What’s your role there?</h4>
					<div class="cr--list-role d-flex flex-column mb-5">
						<div v-for="(role, index) in roles" :key="index" class="cr--role__card cursor-pointer" @click="handleChooseRole">
							<div class="cr--role__card-inner px-3 py-4">
								<div class="container-fluid">
									<div class="row">
										<div class="col-2 d-flex align-items-center justify-content-center">
											<img :src="role.image" :alt="role.title">
										</div>
										<div class="col-10 d-flex justify-content-between align-items-center">
											<div class="cr--role__main d-flex flex-column">
												<h4 class="cr--role__title">{{ role.title }}</h4>
												<p class="cr--role__description m-0">{{ role.description }}</p>
											</div>
											<div class="mr-2">
												<svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
													<path d="M12.8571 3.25269e-06L12.0238 0.86772L17.7382 6.94213L-2.98032e-07 6.94213L-2.98033e-07 8.1818L17.7382 8.1818L12.0238 14.1323L12.8572 15L20 7.56197L12.8571 3.25269e-06Z" fill="white"/>
												</svg>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        </b-modal>
    </div>
</template>

<script>
export default {
	data() {
		return {
			roles: [
				{
					image: require('@/assets/images/icons/ic_educator-content.svg'),
					title: 'Edu Creator',
					description: 'I create content for schools or universities'
				},
				{
					image: require('@/assets/images/icons/ic_content-creator.svg'),
					title: 'Content Creator',
					description: 'I create content for businesses'
				}
			]
		}
	},

	methods: {
		handleChooseRole() {
			this.$router.push({ name : 'Soca-Certified-Creator' })
		}
	}
}
</script>

<style scoped>
.button--cr__close {
	width: 27px;
	height: 27px;
	border-radius: 50%;
	background-color: #fff;
	color: black;
}

.cr--main {
	gap: 14px;
}

.cr--list-role {
	gap: 24px;
}

.cr--title {
	color: #FFF;
	font-size: 22px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.cr--role__card {
	border-radius: 8px;
	background-image: linear-gradient(to bottom, #FFFFFF33, #ffffff00);
	/* background-image: 1px solid rgba(255, 255, 255, 0.20); */
	padding: 1px;
}

.cr--role__card-inner {
	background: #222222;
	border-radius: 8px;
	backdrop-filter: blur(2px);
}

.cr--role__main {
	gap: 4px;
}

.cr--role__title {
	color: #FFF;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.cr--role__description {
	color: #FFF;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
</style>

<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#choose-role-modal .modal-content{
    border-radius: 12px !important;
    background-color: #1f1f1f;
    font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
	color: #fff;
}

.cr__modal + .modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}

.cr__dialog {
	max-width: none;
}

.cr__content {
	width: 740px;
}
</style>
