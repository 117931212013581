import axios from "../../config/axios"
import { base_url_api_public } from '../../config/base_url'

export async function generateAPIKey(formData) {
    return axios.post(base_url_api_public +'api/generate-api-key', formData)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error.response }
            return data;
        })
}

export async function regenerateAPIKey() {
    return axios.post(base_url_api_public +'api/regenerate-api-key')
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error.response }
            return data;
        })
}

export async function getAPIKEY() {
    return axios.get(base_url_api_public +'api/api-key')
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error.response }
            return data;
        })
}

export async function getApiLog(query) {
    return axios.get(base_url_api_public +'api/log-api?page='  + query.page + '&limit=' + query.limit + '&option_date=' + query.options + '&start_date=' + query.start_date + '&end_date=' + query.end_date)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error.response }
            return data;
        })
}

export async function getDetailApiLog(id) {
    return axios.get(base_url_api_public +'api/log-api/' + id)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error.response }
            return data;
        })
}

export async function getRequestDashboard(query) {
    return axios.get(base_url_api_public +'api/dashboard/log-api?option_date=' + query.options + '&start_date=' + query.start_date + '&end_date=' + query.end_date)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error.response }
            return data;
        })
}

export async function getUsageAllocationDashboard(query) {
    return axios.get(base_url_api_public +'api/dashboard/usage-alloc?month=' + query.month + '&year=' + query.year + '&calculate=' + query.calculate)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error.response }
            return data;
        })
}