import axios from "../../config/axios";
import ApiRoute from '../api.route';
import { base_url_general } from "../../config/base_url"

export async function getList(data) {
    return axios.get(`${base_url_general+ApiRoute.plansHistory}?page=${data.page}&limit=${data.limit}&option_date=${data.options}&start_date=${data.start_date}&end_date=${data.end_date}`)
    .then(res => {
        const data = res.data
        return data;
      })
      .catch((error) => {
        const data = { status: false, response: error }
        console.log(error)
        return data;
    })
}
export async function getPlanUsageProgress() {
    return axios.get(`${base_url_general+ApiRoute.plansUsageProgress}`)
    .then(res => {
        const data = res.data
        return data;
      })
      .catch((error) => {
        const data = { status: false, response: error }
        console.log(error)
        return data;
    })
}
export async function sendFeedbackCancelPlans(data) {
    return axios.post(`${base_url_general+ApiRoute.sendFeedbackCancelSubscription}`, data)
    .then(res => {
        const data = res.data
        return data;
      })
      .catch((error) => {
        const data = { status: false, response: error }
        console.log(error)
        return data;
    })
}

export async function cancelPlans() {
  return axios.patch(`${base_url_general+ApiRoute.cancelSubscription}`)
  .then(res => {
      const data = res.data
      return data;
    })
    .catch((error) => {
      const data = { status: false, response: error }
      console.log(error)
      return data;
  })
}