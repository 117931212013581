<template>
    <div>
        <b-modal id="ins-modal" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm" @hidden="$emit('close-insm')">
            <div class="d-flex justify-content-end mb-2">
				<button
					class="btn d-flex justify-content-center align-items-center close-image"
					@click="$bvModal.hide('ins-modal')"
				>
					<b-icon icon="x"></b-icon>
				</button>
			</div>
            <div class="mt-2 text-center text-white mb-3">
                <img src="@/assets/images/icons/modal_warning.svg" alt="Alert" class="img-fluid">
                <div class="mt-3">
                    <h5 class="font-weight-bold mb-2" style="font-size:16px;">Invitation is not sent!</h5>
                    <h6 class="font-weight-normal">The Email</h6>
                    <h6 v-for="(item, index) in listEmail" :key="index" class="font-weight-normal">
                      {{ formatEmail(item.email, index) }}
                    </h6>
                </div>
                <div class="cc__actions d-flex justify-content-center mt-3">
                    <button class="btn cc__button-oke" @click="$emit('close-insm')"><p class="mx-2 mb-0 font-weight-bold text-white">Close</p></button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
  props : {
    listEmail : {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  methods : {
    formatEmail(email, index) {
      if (index === this.listEmail.length - 1) {
        return `${email} has joined the workspace`; // Tidak menambahkan koma jika ini elemen terakhir
      } else {
        return `${email},`; // Menambahkan koma untuk elemen-elemen lainnya
      }
    }
  }
}
</script>

<style scoped>
.btn-close {
  background-color: #ededed;
  border-radius: 50%;
  width: 15px;
  height: 26px;
}

.cc__actions {
	gap: 10px;
}

.cc__button-cancel, .cc__button-oke {
  border-radius: 4px;
  font-size: 15px;
  font-weight: 500;
  transition: background-color 0.25s;
  color: #fff;
}

.cc__button-cancel {
  background-color: #2d2d2d;
}

.cc__button-cancel:hover {
  background-color: #2C2C2C;
}

.cc__button-oke {
  background-color: #6D75F6;
}

.cc__button-oke:hover {
  background-color: #545EED;
}
</style>

<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#ins-modal .modal-content{
    background-color: #222222;
	border-radius: 12px !important;
    font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}

</style>