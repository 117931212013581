<template>
    <div>
        <b-modal id="create-organization" no-close-on-backdrop centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
            <h4 class="title mb-4 mt-3">Create organization</h4>
            <div class="text-white px-3">
                <label for="organization_name">Input Organization Name</label>
                <input v-model="organization_name" type="text" class="form-control" id="organization_name" placeholder="Soca AI">
            </div>
            <div class="d-flex justify-content-center mt-4">
                <button class="btn btn-cancel mr-3" @click="$bvModal.hide('create-organization')"><p class="mx-2 mb-0 font-weight-bold text-white">Cancel</p></button>
                <button class="btn btn-purple" @click="$emit('click:generate-key')"><p class="mx-2 mb-0 font-weight-bold text-white">Save</p></button>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      organization_name: this.value
    }
  },
  watch: {
    value: {
      handler(newValue) {
        this.organization_name = newValue
      },
      deep: true
    },
    organization_name: {
      handler(newValue) {
        this.$emit('input', newValue)
      },
      deep: true,
    }
  }
}
</script>

<style scoped>
.btn-cancel {
  background-color: #2D2D2D;
  border: 1px solid #2D2D2D;
  color: white;
  box-shadow: none !important;
}
.btn-purple {
  background-color: #6d75f6;
  border: 1px solid #6d75f6;
  color: white;
  box-shadow: none !important;
}
.form-control {
    background-color: transparent;
    border: 1px solid #585859;
    color: #fff;
}
</style>

<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#create-organization .modal-dialog {
    max-width: 415px !important;
}
#create-organization .modal-content {
  background-color: #222222;
  font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#create-organization .modal-content {
  border-radius: 10px !important;
  background-color: #1f1f1f;
}

#create-organization .title {
    color: #FFF;
    text-align: center;
    font-family: 'Avenir Next';
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
}
#create-organization .modal-content {
  border-radius: 10px !important;
  background-color: #1f1f1f;
}

.btn-purple {
  background-color: #6d75f6;
  border: 1px solid #6d75f6;
  color: white;
  box-shadow: none !important;
}

button {
  box-shadow: none !important;
}
</style>